<template>
	<div>
		<el-row>
			<el-col>
				<el-form :inline='true' :model="filters" ref="searchForm">
					<el-form-item label="视频名称：">
						<el-input v-model="filters.videoName" placeholder="视频名称搜索" width="120px"></el-input>
					</el-form-item>
					<el-form-item label="视频类型：" prop="videoType">
						<el-select v-model="filters.videoType" style="width: 160px" placeholder="请选择视频类型" filterable clearable>
							<el-option
									v-for="item in videoTypeList"
									:key="item.value"
									:label="item.label"
									:value="item.value"
							></el-option>
						</el-select>
					</el-form-item>
					<el-form-item label="是否启用：" prop="videoType">
						<el-select v-model="filters.isEnable" style="width: 160px" placeholder="请选择是否启用" filterable clearable>
							<el-option
									v-for="item in videoOrenableList"
									:key="item.value"
									:label="item.label"
									:value="item.value"
							></el-option>
						</el-select>
					</el-form-item>
					<el-form-item label="是否有标签：" prop="">
						<el-select v-model="filters.videoTagStatus" @change="changeVideoStatus" style="width: 160px" placeholder="请选择是否有标签" filterable clearable>
							<el-option
									v-for="item in videoRadioList"
									:key="item.value"
									:label="item.label"
									:value="item.value"
							></el-option>
						</el-select>
					</el-form-item>
					<el-form-item label="视频标签：" prop="" v-if="filters.videoTagStatus === true">
						<el-select  v-model="filters.videoTagCodeList" style="width:260px" multiple placeholder="请选择视频类型" filterable clearable>
							<el-option
									v-for="item in videoTagList"
									:key="item.Id"
									:label="item.Name"
									:value="item.Id"
							></el-option>
						</el-select>
					</el-form-item>
					<el-form-item>
						<toolbar :buttonList="buttonList" @callFunction="callFunction"></toolbar>
					</el-form-item>
				</el-form>
			</el-col>
		</el-row>
		<!-- 列表 -->
		<el-table
				:data="tableData"
				border
				style="width: 100%"
				v-loading="listLoading"
				ref="multipleTable"
				@current-change='currentChange'
				:highlight-current-row='true'
		>
			<el-table-column type='index' label="序号" width='80' align="center" :index='indexMethod'></el-table-column>
			<el-table-column prop="VideoType" label="视频类型"  width='' align="center" >
				<template #default='scope'>
					<el-tag v-if='!scope.row.VideoType' type='success'>公有</el-tag>
					<el-tag v-else type='warning'>私有</el-tag>
				</template>
			</el-table-column>
			<el-table-column prop="VideoName" label="视频名称"  width='' align="center" ></el-table-column>
			<el-table-column prop="VideoTagList" label="视频标签"  width='' align="center" ></el-table-column>
			<el-table-column prop="CreateTime" label="创建时间"  width='' align="center" ></el-table-column>
			<el-table-column prop="IsEnable" label="启用标识"  width='' align="center" >
				<template #default='scope'>
					<el-tag v-if='scope.row.IsEnable' type='success'>启用</el-tag>
					<el-tag v-else type='warning'>禁用</el-tag>
				</template>
			</el-table-column>
			<el-table-column prop="fileUrl" label="操作" width='120' align="center" >
				<template #default='scope'>
					<el-button type="text" @click="preview(scope.row)">{{'预览'}}</el-button>
				</template>
			</el-table-column>
		</el-table>
		<!--底部工具条-->
		<el-col :span="24" class="pageBar" style="margin:20px 0">
			<el-pagination
					@size-change="handleSizeChange"
					@current-change="handleCurrentChange"
					:current-page="pages.pageIndex"
					:page-sizes="pages.pageArr"
					:page-size="pages.pageSize"
					layout="total, sizes, prev, pager, next, jumper"
					:total="pages.dataCount">
			</el-pagination>
		</el-col>
		<el-dialog
				:title= "editFalse?'编辑':'添加视频'"
				:visible.sync="dialogs.addType.dialog"
				width="55%"
				style="min-width: 300px"
				:close-on-click-modal='false'
		>
			<el-form :model="addForm"  :ref="addFormRef" label-width="140px">
				<el-form-item label="视频名称：" prop="videoName">
					<el-input v-model="addForm.videoName" placeholder="请输入视频名称" style="width: 50%"></el-input>
				</el-form-item>
				<el-form-item label="视频类型：" prop="videoType">
					<el-select v-model="addForm.videoType" style="width: 50%" placeholder="请选择视频类型" filterable clearable>
						<el-option
								v-for="item in videoTypeList"
								:key="item.value"
								:label="item.label"
								:value="item.value"
						></el-option>
					</el-select>
				</el-form-item>
				<el-form-item label="视频标签：" prop="videoTagCodeList">
					<el-select multiple v-model="addForm.videoTagCodeList" style="width:50%" placeholder="请选择视频类型" filterable clearable>
						<el-option
								v-for="item in videoTagList"
								:key="item.Id"
								:label="item.Name"
								:value="item.Id"
						></el-option>
					</el-select>
				</el-form-item>
				<el-form-item label="启用标识：" prop="isEnable">
					<el-radio v-model="addForm.isEnable" :label="true">是</el-radio>
					<el-radio v-model="addForm.isEnable" :label="false">否</el-radio>
				</el-form-item>
				<el-form-item required prop="fileId" label="视频文件：">
					<template>
						<div class="hello">
							<div>
								<input type="file" class="upload" @change="doUpload" ref="inputer" multiple />
								<!--								<el-button type="primary" size="small" @click="startAll">全部开始</el-button>-->
								<!--								<el-button type="warning" size="small" @click="pauseAll">全部暂停</el-button>-->
								<!--								<el-button type="danger" size="small"  @click="clearAll">全部删除</el-button>-->
							</div>
							
							<div>
								<el-table :data="tableData1" border style="width: 100%">
									<!--									<el-table-column prop="id" label="ID" width="180">-->
									<!--									</el-table-column>-->
									<el-table-column prop="fileName" label="文件名" width="">
									</el-table-column>
									<el-table-column prop="size" label="文件大小" width="120">
										<template slot-scope="scope">{{ transformSize(scope.row.size)}}</template>
									</el-table-column>
									<el-table-column prop="progress" label="进度" width="180">
										<template slot-scope="scope">
											<el-progress :text-inside="true" :stroke-width="26" :percentage="scope.row.progress"></el-progress>
										</template>
									
									</el-table-column>
									<el-table-column prop="progress" label="操作" width="160">
										<template slot-scope="scope" >
											<el-button type="text" size="small" @click="start(scope.row.id)">开始</el-button>
											<el-button type="text" size="small" @click="stop(scope.row.id)">暂停</el-button>
											<el-button type="text" size="small" @click="remove(scope.row.id)">删除</el-button>
										</template>
									</el-table-column>
								</el-table>
							</div>
						</div>
					</template>
				</el-form-item>
			</el-form>
			<span v-if="!editFalse" slot="footer" class="dialog-footer">
                <el-button @click="dialogs.addType.dialog = false">取 消</el-button>
                <el-button type="primary" @click="addConfirm" :loading="loading">确认</el-button>
            </span>
			<span v-else slot="footer" class="dialog-footer">
                <el-button @click="dialogs.addType.dialog = false">取 消</el-button>
                <el-button type="primary" @click="showConfirm" :loading="loading">确认</el-button>
            </span>
		</el-dialog>
		<el-dialog
				title= "提示"
				:visible.sync="dialogs.addType.confirm"
				width="30%"
				:close-on-click-modal='false'
		>
			<el-form :model="addForm"  ref="addFormRef" label-width="100px">
				<el-form-item label="职业功能：">
					{{'该分类下的数据将停止使用”，确定禁用吗？'}}
				</el-form-item>
			</el-form>
			<span  slot="footer" class="dialog-footer">
          <el-button @click="dialogs.addType.confirm = false">取 消</el-button>
          <el-button type="primary" @click="editConfirm" :loading="loading">确认</el-button>
      </span>
		</el-dialog>
		<el-dialog
				title= "预览"
				:visible.sync="dialogs.addType.firurl"
				width="50%"
				:show-close = false
		>
			<!--      <iframe :src="videoFile"></iframe>-->
			
			<!--      <video controls width="100%" :src="videoFile">-->
			<!--        &lt;!&ndash;          <source :src="videoFile" type="video/mp4">&ndash;&gt;-->
			<!--        &lt;!&ndash;            <source src="https://vdept3.bdstatic.com/mda-qd8h47v4wemqewex/sc/cae_h264/1712666300875566676/mda-qd8h47v4wemqewex.mp4?v_from_s=hkapp-haokan-hbf&auth_key=1712749130-0-0-75efb69ddd9d91c9e59159b32ad10527&bcevod_channel=searchbox_feed&pd=1&cr=2&cd=0&pt=3&logid=2330032235&vid=16910047636772584045&klogid=2330032235&abtest=" type="video/mp4">&ndash;&gt;-->
			<!--      </video>-->
			<template >
				<div id="player"></div>
			</template>
			<span  slot="footer" class="dialog-footer">
		    <el-button type="primary" @click="closePreview" :loading="loading">关闭</el-button>
	    </span>
		</el-dialog>
		<el-dialog :visible.sync="dialogVisible" width="50%">
			<template >
				<div id="preview"></div>
			</template>
		
		</el-dialog>
	</div>
</template>

<script>
import util from "../../../util/date";
import Toolbar from "../../components/Toolbar";
import { getButtonList } from "../../promissionRouter";
import photo from '../../assets/视频文件.png'
import {
	getVideoManageListPage,
	getDataDictionaryList,
	updateExamIdentifyPointVideo,
	getVideoUploadParams,
	addVideoByCode, deleteVideoManage, updateVideoManage
} from '../../api/api'
import PlvVideoUpload from '@polyv/vod-upload-js-sdk'
import Login from "@/views/Login.vue";

export default {
	components:{Toolbar},
	data() {
		return {
			buttonList:[],
			tableData: [],
			tableData1: [],
			registerType:[],
			areaArr: [
				{name:'禁用',id:2},
				{name:'启用',id:1}
			],
			listLoading: false,
			//关于分页的obj
			pages: {
				pageSize: 20,//默认每页条数
				pageArr: [10, 20, 30, 40],//默认每页条数选择
				pageIndex: 1, //默认进入页
				dataCount: 20, //默认总页数
			},
			filters: {
				videoName:undefined,//视频名称
				videoType:undefined,//视频类型
				isEnable:undefined,//是否启用
				videoTagStatus:undefined,//是否有标签
				videoTagCodeList:[],//视频标签
			},
			dialogs:{
				addType:{
					dialog:false,
					confirm:false,
					firurl:false,
				},
				confirm:false,
			},
			addForm:{
				videoName:undefined,
				videoType:undefined,
				videoTagCodeList:undefined,
				videoManageId:undefined,//视频ID
				id:undefined,
				isEnable:undefined,
			},
			addFormRef: {
				videoName: [
					{ required: true, message: '请输入视频名称', trigger: 'blur' }
				],
				videoType: [
					{ required: true, message: '请选择视频类型', trigger: 'change' }
				],
				videoRadio: [
					{ required: true, message: '请选择是否为关键视频', trigger: 'change' }
				],
				videoTagCodeList: [
					{ required: true, message: '请输入视频标签', trigger: 'blur' }
				]
			},
			videoTypeList:[
					{label:'公有',value:false},
					{label:'私有',value:true},
			],
			videoRadioList:[
				{label:'有',value:true},
				{label:'无',value:false},
				
			],
			videoOrenableList:[
				{label:'启用',value:true},
				{label:'禁用',value:false},
			],
			videoTagList:[],
			loading:false,
			professionType: [],
			editFalse:false,
			videoFile:undefined,
			professionList:[],
			professionLists:[],
			pointNums:[],
			dialogImageUrl:undefined,
			dialogVisible:false,
			fileList:undefined,
			uploadFile:undefined,
			uploadList:undefined,
			vodPlayerJs: "https://player.polyv.net/resp/vod-player/latest/player.js",
			// vodPlayerJs: "https://player.polyv.net/resp/vod-player-drm/canary/player.js",
			previewBlooean:false,
			skillTypeId: [
				{name:'关键技能',id:1},
				{name:'非关键技能',id:2},
			],
			fileSetting: {
				desc: '测试视频上传', // 描述
				cataid: 1733905060001, // 分类ID 可以后端传递 也可以不写 或写死
				tag: 'tag', // 标签
				luping: 0, // 是否开启视频课件优化处理，对于上传录屏类视频清晰度有所优化：0为不开启，1为开启
				keepsource: 1 // 是否源文件播放（不对视频进行编码）：0为编码，1为不编码
			},
			userid:undefined,
			
			videoUpload: null, // 视频上传实例
			// userid: '',//从点播后台查看获取
			ptime: '', // 当前时间戳
			videoId:undefined,
			fileUpload:undefined,
		}
	},
	methods: {
		changeVideoStatus(val){
			if (val === false){
				this.filters.videoTagCodeList=undefined
			}
		},
		closePreview(){
			// 清空 #player 所在的 div
			const playerDiv = document.querySelector("#player");
			const preViewDiv = document.querySelector("#preview");
			if (playerDiv) {
				playerDiv.innerHTML = '';
			}
			if (preViewDiv){
				preViewDiv.innerHTML = '';
			}
			this.onPause()
			this.dialogs.addType.firurl = false
		},
		start(uploaderid) {// 单个上传
			console.log(uploaderid)
			this.videoUpload.resumeFile(uploaderid);
		},
		stop(uploaderid) {// 单个暂停
			console.log(uploaderid)
			this.videoUpload.stopFile(uploaderid);
		},
		remove(uploaderid) {// 单个删除
			console.log(uploaderid)
			this.videoUpload.removeFile(uploaderid);
			this.tableData1 = this.tableData1.filter((item) => item.id !== uploaderid)
		},
		startAll() {// 全部上传
			if (this.videoUpload) {
				this.videoUpload.startAll();
			}
		},
		pauseAll() {// 全部暂停
			if (this.videoUpload) {
				this.videoUpload.stopAll();
			}
		},
		clearAll() {// 全部删除
			if (this.videoUpload) {
				this.videoUpload.clearAll();
				this.tableData1 = []
				// this.$refs.inputer.value =''
			}
		},
		doUpload() {// 选择文件
			this.getUserData()
			let inputDOM = this.$refs.inputer; // 通过DOM取文件数据
			console.log(inputDOM.files)
			if ([...inputDOM.files].length > 0) {
				[...inputDOM.files].forEach((file, index, arr) => {
					let fileSetting = { // 文件上传相关信息设置
						title: file.name, // 标题
						desc: 'jssdk插件上传', // 描述
						cataid: this.fileSetting.cataid, // 上传分类目录ID
						tag: '', // 标签
						luping: 0, // 是否开启视频课件优化处理，对于上传录屏类视频清晰度有所优化：0为不开启，1为开启
						keepsource: 0, // 是否源文件播放（不对视频进行编码）：0为编码，1为不编码
						state: '' //用户自定义信息，如果提交了该字段，会在服务端上传完成回调时透传返回。
					}
					let uploadManager = this.videoUpload.addFile(
							file, // file 为待上传的文件对象
							{
								FileStarted: this.onFileStarted,// 文件开始上传回调
								FileProgress: this.onFileProgress,// 文件上传中回调
								FileSucceed: this.onFileSucceed,// 文件上传成功回调
								FileFailed: this.onFileFailed,// 文件上传失败回调
								FileStopped: this.onFileStopped,// 文件上传停止回调
							},
							fileSetting
					);
					this.addTableData(uploadManager)
				})
			}
		},
		onFileStarted(data) {
			console.log("文件上传开始: ", data);
			this.tableData1.filter((item) => item.id === data.uploaderid)[0].progress = 0
		},
		onFileProgress(data) {
			let p = parseInt(data.progress * 100);// 上传的进度条
			console.log("文件上传中: ", data);
			this.tableData1.filter((item) => item.id === data.uploaderid)[0].progress = p
			
		},
		onFileSucceed(data) {
			console.log("文件上传成功: ", data);
			this.fileUpload = {
				// videoName:data.fileData.filename,
				VideoSize:data.fileData.size,
				VideoCode:data.fileData.vid,
			}
			
		},
		onFileFailed(data) {
			console.log("文件上传失败: ", data);
		},
		onFileStopped(data) {
			console.log("文件上传停止: ", data);
		},
		addTableData(data) { // 增加表格数据
			let obj = {
				id: data.id,
				fileName: data.fileData.title,
				size: data.fileData.size,
				progress: 0
			}
			if (this.tableData1&&this.tableData1.length<1){
				this.tableData1.push(obj)
			}else {
				this.$message({
					message:"视频只能添加一条！",
					type:'warning'
				})
			}
		},
		// autoUpdateUserData(timer, videoUpload) { // 启动获取用户信息
		// 	this.getUserData(videoUpload);
		// 	if (timer) {
		// 		clearTimeout(timer);
		// 		timer = null;
		// 	}
		// 	timer = setTimeout(() => {
		// 		this.autoUpdateUserData(timer, videoUpload);
		// 	}, 3 * 60 * 1000);
		// },
		getUserData() { // 获取用户详细信息
			// this.ptime = new Date().getTime()
			console.log(2222222)
			let params = {}
			getVideoUploadParams(params).then(res=>{
				console.log(res,'kjklsjdfk')
				if (res.data.Success){
					const hash = res.data.Response.Hash
					const sign = res.data.Response.Sign
					const userid = (res.data.Response.UserId)
					const ptime = parseInt(res.data.Response.Ts)
					let userData = {
						userid: userid,
						ptime: ptime,
						sign: sign,
						hash: hash
					};
					this.videoUpload.updateUserData(userData);
				}
			})
			
		},
		transformSize(bytes) {// 文件大小转换
			const bt = parseInt(bytes);
			let result;
			if (bt === 0) {
				result = '0B';
			} else {
				const k = 1024;
				const sizes = ['B', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
				const i = Math.floor(Math.log(bt) / Math.log(k));
				if (typeof i !== 'number') {
					result = '-';
				} else {
					result = (bt / Math.pow(k, i)).toFixed(2) + sizes[i];
				}
			}
			return result;
		},
		
		
		
		splitQuestionId(val){
			// console.log(1123332)
			if (val){
				this.addForm.questionTypeCode = val.substring(0,8)
			}
		},
		changepointCode(val){
			const obj = this.pointNums.filter(item=>item.pointCode === val)
			console.log(obj,'obj')
			this.pointId = obj[0].id
		},
		handleAdd(){
			this.tableData1 = []
			this.fileList = []
			this.previewBlooean = true
			this.addForm={
				VideoName:undefined,
				VideoType:undefined,
				VideoRadio:undefined,
				VideoLabel:undefined,
				VideoManageId:undefined,//视频ID
				Id:undefined,
			}
			
			this.editFalse = false
			this.dialogs.addType.dialog = true;
		},
		//获取视频标签列表
		getVideoLabelList(){
			let params = {
				projectId:window.localStorage.getItem('projectId')
			}
			getDataDictionaryList(params).then(res=>{
				if (res.data.Success){
					console.log(res.data.Response,'标签列表')
					this.videoTagList = res.data.Response
				}
			})
		},
		changeVal(val){
			console.log(val,'val112222')
			
		},
		rowClass () {
			return 'padding:8px!important;'
		},
		callFunction(item) {
			this[item.Func].apply(this, item);
		},
		getDataList(){
			this.pages = {
				pageIndex: 1,
				pageSize: 20,
			}
			this.getListData()
		},
		// 列表查询接口
		getListData(){
			let params = {
				pageIndex: this.pages.pageIndex,
				pageSize: this.pages.pageSize,
				...this.filters,
				// videoTagCodeList:this.filters.videoTagCodeList,//视频标签
			}
			if (this.filters.videoTagCodeList&&this.filters.videoTagCodeList.length>0){
				params.videoTagCodeList = this.filters.videoTagCodeList.join(',')
			}
			console.log(params.videoTagCodeList,'this.filter')
			this.listLoading = true
			getVideoManageListPage(params).then(res => {
				let result = res.data
				if(result.Success) {
					console.log(result,'result')
					
					this.tableData = result.Response.Data
					console.log(result.Response.Data,'result.Response.Data')
					this.pages.dataCount = result.Response.DataCount
				}
				this.listLoading = false
			})
		},
		//预览
		preview(row){
			this.needData = row
			if (row.PlaySafeCode === null){
				this.$message({
					message:'视频正在审核中，请稍后再试！',
					type:'warning'
				})
				return;
			}
			this.loadPlayerScript(this.loadPlayer);
			if (this.player) {
				// this.onPause();//两个监听事件
				// this.onPlay();
				// this.onPreview()
			}
			this.dialogs.addType.firurl = true;
		},
		async addConfirm(){
			let params = {
				VideoName:this.addForm.videoName,
				VideoType:this.addForm.videoType,
				VideoTagCodeList:this.addForm.videoTagCodeList,
				// videoManageId:undefined,//视频ID
				// id:undefined,
				IsEnable:this.addForm.isEnable,
				...this.fileUpload,
			}
			
			addVideoByCode(params).then(res=>{
			
			
			if (res.data.Success){
				this.getDataList()
				this.dialogs.addType.dialog = false ;
				this.$message({
					message:res.data.Message,
					type:'success'
				})
				this.$refs.inputer.value = '';
			}else{
				this.$message({
					message:res.data.Message,
					type:'warning'
				})
			}
			})
		},
		
		loadPlayerScript(callback) {
			
			if (!window.polyvPlayer) {
				const myScript = document.createElement("script");
				myScript.setAttribute("src", this.vodPlayerJs);
				myScript.onload = callback;
				document.body.appendChild(myScript);
			} else {
				callback();
			}
		},
		// 暂停事件
		onPause() {
			let that=this;
			
			this.player.on("s2j_onVideoPause", function (e) {
				that.flag=true;
			});
		},
		// 播放事件
		onPlay() {
			const _that = this;
			
			this.player.on("s2j_onVideoPlay", function (e) {
				_that.flag=false;
			});
		},
		//预览时长
		onPreview(){
			this.player.on('s2j_onPlayStart', () => { //订阅视频开始播放事件
				console.info('视频开始播放');
				// this.player.j2s_seekVideo(60); //视频seek到第60秒
			});
		},
		
		loadPlayer() {
			// 清空 #player 所在的 div
			const playerDiv = document.querySelector("#player");
			const preViewDiv = document.querySelector("#preview");
			if (playerDiv) {
				playerDiv.innerHTML = '';
			}
			if (preViewDiv){
				preViewDiv.innerHTML = '';
			}
			const polyvPlayer = window.polyvPlayer;
			
			this.player = polyvPlayer({
				wrap: this.previewBlooean?'#preview':"#player" ,
				width: "100%",
				height: "544",
				vid: this.needData.VideoCode,
				autoplay: false,
				// ban_preview_video: "on",
				// allowFullscreen:false,
				code:'off',
				playsafe:this.needData.PlaySafeCode,
			});
			this.previewBlooean = false ;
		},
		
		handleEdit(){
			if (!this.needData){
				this.$message({
					message:'请选择一条数据',
					type:'warning'
				})
				return;
			}
			this.fileUpload = {}
			this.addForm = {
				videoName:this.needData.VideoName,
				videoType:this.needData.VideoType,
				videoTagCodeList:this.needData.TagCodes.split(',').map(item=>item = +item),
				// videoManageId:undefined,//视频ID
				isEnable:this.needData.IsEnable,
			}
			console.log(this.addForm,'this.addform')
			this.tableData1 = [];
			this.editFalse = true;
			if(this.needData&&this.needData.VideoCode){
				let obj = {
					id: this.needData.Id,
					fileName: this.needData.VideoName,
					size: this.needData.VideoSize,
					progress: 100
				}
				this.tableData1.push(obj)
			}
			
			// this.fileList.push({
			// 	vid:this.needData.videoInfo.fileCode,
			// 	playSafe:this.needData.videoInfo.playSafeCode,
			// 	name:this.needData.videoInfo.fileName,
			// 	file:this.needData.videoFile.firstImageUrl
			// })
			this.dialogs.addType.dialog = true;
		},
		//删除
		handleDel() {
			if(this.needData) {
				this.$confirm(`确定将永久删除？`,"提示",{
				}).then(() => {
					let params = {
						Id: this.needData.Id
					}
							deleteVideoManage(params).then((res) => {
								if (res.data.Success){
									this.getDataList()
									this.$message({
										message:res.data.Message,
										type:'success'
									})
								}else{
									this.$message({
										message:res.data.Message,
										type:'warning'
									})
								}
					})
				})
				.catch((err) => {

				})
			} else {
				this.$message({
					message:'请选择要删除的数据',
					type:'warning'
				})
			}
		},
		showConfirm() {
			
				this.editConfirm()
			
		},
		async editConfirm(){
			let params = {
				VideoName:this.addForm.videoName,
				VideoType:this.addForm.videoType,
				VideoTagCodeList:this.addForm.videoTagCodeList,
				// videoManageId:undefined,//视频ID
				// id:undefined,
				IsEnable:this.addForm.isEnable,
				...this.fileUpload,
				Id: this.needData.Id
			}
			if (!params.VideoCode){
				params.VideoCode = this.needData.VideoCode
			}
			if (!params.VideoSize){
				params.VideoSize = this.needData.VideoSize
			}
			const res = await updateVideoManage(params)
			if (res.data.Success){
				this.getDataList()
				this.$refs.inputer.value = '';
				this.dialogs.addType.dialog = false ;
				this.dialogs.addType.confirm = false ;
				this.$message({
					message:res.data.Message,
					type:'success'
				})
			}else{
				this.$message({
					message:res.data.Message,
					type:'warning'
				})
			}
		},
		//禁用
		// async disable(){
		// 	if (!this.needData){
		// 		this.$message({
		// 			message:'请选择一条数据',
		// 			type:'warning'
		// 		})
		// 		return;
		// 	}
		// 	this.addForm = {
		// 		...this.needData,
		// 	}
		// 	let params = {
		// 		...this.addForm,
		// 		ddCommonStatus:2,//状态，1:启用;2:禁用
		// 	}
		// 	const res = await updateExamIdentifyPointVideo(params)
		// 	if (res.data.success){
		// 		this.getDataList()
		// 		this.dialogs.addType.dialog = false ;
		// 		this.$message({
		// 			message:res.data.message,
		// 			type:'success'
		// 		})
		// 	}else{
		// 		this.$message({
		// 			message:res.data.message,
		// 			type:'warning'
		// 		})
		// 	}
		//
		// },
		
		
		
		
		// // 获取省
		// getAreaList() {
		//   let params = {
		//     parentId: 1,
		//   }
		//   getAreaDictionaryList(params).then(res => {
		//     if (res.data.success) {
		//       this.areaArr = res.data.response.reverse();
		//     }
		//   })
		// },
		//获取字符空间
		// async getNamespace(){
		// 	let params = {
		// 		pageIndex:undefined,
		// 		pageSize:undefined,
		// 	}
		// 	const res = await  getByNamespace(params)
		// 	console.log(res,'res')
		// },
		
		
		// 点击table某一行
		currentChange(val) {
			this.needData = val
			console.log(val,'val')
		},
		// 分页
		handleCurrentChange(val) {
			this.pages.pageIndex = val;
			this.getListData();
		},
		handleSizeChange(val){
			this.pages.pageSize = val;
			this.getListData();
		},
		// 时间格式化
		formatCreateTime: function(row, column) {
			return !row || row == ""
					? ""
					: util.formatDate.format(new Date(row), "yyyy-MM-dd hh:mm");
		},
		// 表格数据分页条数累计
		indexMethod(index) {
			let currentPage = this.pages.pageIndex
			let pageSize = this.pages.pageSize
			return (index + 1) + (currentPage - 1) * pageSize
		},
	},
	watch: {
		'addForm.questionCode': {
			handler(newQuestionCode, oldQuestionCode) {
				// 调用 splitQuestionId 方法并更新 addForm.questionTypeCode
				this.splitQuestionId(this.addForm.questionCode);
			},
			immediate: true, // 立即执行一次以确保初始值也被处理
		},
	},
	created(){
		this.getVideoLabelList()
		this.utils = util
		let routers = window.localStorage.getItem('router')
				? JSON.parse(window.localStorage.getItem('router'))
				: [];
		this.buttonList = getButtonList(this.$route.path, routers);
		// this.uploadFile = uploadVideoFile
		this.videoUpload = new PlvVideoUpload({
			region: 'line1', // (可选)上传线路, 默认line1
			events: {
				Error: (err) => { // 错误事件回调
					console.log(err);
					let errMag = `（错误代码：${err.code}）${err.message}`;
					this.$alert(errMag, '标题名称', {
						confirmButtonText: '确定',
						type: 'error',
					});
				},
				UploadComplete: () => { // 全部上传任务完成回调
					console.info('上传结束：', this.videoUpload);
					console.log(this.tableData)
					this.$message({
						message: '全部上传任务完成',
						type: 'success'
					});
				}
			}
		});
		this.getListData();
		// this.getAreaList()
	},
	computed:{
		
		headers(){
			return {
				"Authorization": 'Bearer ' + localStorage.getItem("Token")
			}
		}
		
	},
	beforeDestroy() {
		clearInterval(this.timer);
	},
	mounted() {
		// this.autoUpdateUserData(null, this.videoUpload);
	},
}
</script>

<style lang="stylus" scoped>
.projectName {
	text-align: center;
}
.projectSelect {
	margin-bottom: 15px;
}
.projectTitle {
	margin-bottom: 15px
}
.replyBtn {
	margin: 10px 0;
	color: #409eff;
	cursor: pointer;
	width: 30px;
}
.replyMessage {
	background: #eee;
	line-height: 30px;
	padding: 5px;
	border-radius: 2px;
	margin: 5px 15px;
}
.titleTimer {
	margin-left: 10px;
}
.green_t {
	color: green;
}
.red_t {
	color: red;
}
</style>
