<template>
  <div>
      <!-- 顶部导航条 -->
      <el-col :span="24">
          <el-form :inline="true">
              <el-form-item label='发布状态:'>
                  <el-select v-model="filter.status" placeholder="请选择">
                    <el-option
                        v-for="item in optionsStatus"
                        :key="item.value"
                        :label="item.name"
                        :value="item.value">
                    </el-option>
                </el-select>
              </el-form-item>
              <el-form-item label='关键字:'>
                  <el-input v-model="filter.ExamProjectName" placeholder="搜索项目名称" clearable @clear='clearClose'></el-input>
              </el-form-item>
              <el-form-item>
                <toolbar :buttonList="buttonList" @callFunction="callFunction"></toolbar>
              </el-form-item>
          </el-form>
      </el-col>
      <!-- 表格主体内容 -->
       <el-table :data="tableData" v-loading="listLoading" border style="width: 100%" @current-change="handleCurrentRow" highlight-current-row>
          <el-table-column prop="ExamProjectNumber" label="项目编号"  align="center"></el-table-column>
          <el-table-column prop="ExamProjectName" label="项目名称"  align="center"></el-table-column>
          <el-table-column prop="EnrollBeginTime" label="报名时间"  align="center">
              <template slot-scope="scope" >
                  {{scope.row.EnrollBeginTime+' - '+scope.row.EnrollEndTime}}
              </template>
          </el-table-column>
          <el-table-column prop="examTime" label="考试时间"  align="center" >
              <template slot-scope="scope" >
                  {{scope.row.ExamBeginTime+' - '+scope.row.ExamEndTime}}
              </template>
          </el-table-column>
          <el-table-column prop="Status" label="发布状态"  align="center">
              <template slot-scope="scope" >
                  <el-tag
                  :type="'warning'"
                  disable-transitions v-if="scope.row.Status== 0">{{"未发布"}}
                </el-tag>
                <el-tag
                  type=""
                  disable-transitions v-if="scope.row.Status== 1">{{"发布中"}}
                </el-tag>
                <el-tag
                  :type="'success'"
                  disable-transitions v-if="scope.row.Status== 2">{{"已发布"}}
                </el-tag>
              </template>
          </el-table-column>
      </el-table>
      <!-- 分页 -->
      <el-row class="pageBar">
          <el-col>
              <el-pagination
                @size-change="handleSizeChange"
                @current-change="handleCurrentChange"
                :current-page="pageBarParams.pageIndex"
                :page-sizes="pageBarParams.pageArr"
                :page-size="pageBarParams.pageSize"
                layout="total, sizes, prev, pager, next, jumper"
                :total="pageBarParams.DataCount">
            </el-pagination>
          </el-col>
      </el-row>     
       <!-- 新增 -->
       <el-dialog
            title="新增项目"
            :visible.sync="addVisible"
            :close-on-click-modal="false"
            @close='addDialogClose'
            width="50%"
        >
            <el-form :model="addForm" label-width="160px" ref="addFormRef" :rules="addFormRules">
                <el-form-item label="项目编号：" prop="projectNumber">
                    <el-input v-model="addForm.projectNumber"></el-input>
                </el-form-item>
                <el-form-item label="项目名称：" prop="projectName">
                    <el-input v-model="addForm.projectName"></el-input>
                </el-form-item>
                <el-form-item label="项目介绍：" prop="projectNotes">
                    <el-input v-model="addForm.projectNotes" type="textarea"></el-input>
                </el-form-item>
<!--                <el-form-item label="考试流程：" prop="examProcedure">-->
<!--                    <el-input v-model="addForm.examProcedure" type="textarea"></el-input>-->
<!--                </el-form-item>-->
                 报名开始时间
                <el-form-item label='报名开始时间：' prop='EnrollBeginTime'>
                    <el-radio-group v-model="EnrollBeginTimeOptionRes" @change='EnrollBeginTimeChoice' >
                        <el-radio :label="2">不限制时间</el-radio>
                        <el-radio :label="1">
                            <el-date-picker
                                v-model="addForm.EnrollBeginTime"
                                type="datetime"
                                placeholder="选择日期时间"
                                :picker-options="pickerOptions"
                                :disabled='EnrollBeginTimeDisable'
                               >
                            </el-date-picker>
                        </el-radio>
                        
                    </el-radio-group>
                </el-form-item>
                <!-- 报名结束时间 -->
                <el-form-item label='报名结束时间：' prop='EnrollEndTime'>
                    <el-radio-group v-model="EnrollEndTimeOptionRes" @change='EnrollEndTimeChoice'>
                        <el-radio :label="2">不限制时间</el-radio>
                        <el-radio :label="1">
                            <el-date-picker
                                v-model="addForm.EnrollEndTime"
                                type="datetime"
                                placeholder="选择日期时间"
                                :picker-options="pickerOptions"
                                :disabled='EnrollEndTimeDisable'>
                            </el-date-picker>
                        </el-radio>
                        
                    </el-radio-group>
                </el-form-item>
                <!-- 考试开始时间 -->
                <el-form-item label="考试开始时间：" prop="ExamBeginTime">
                    <el-radio-group v-model="ExamBeginTimeOptionRes" @change='ExamBeginTimeChoice' >
                        <el-radio :label="2">不限制时间</el-radio>
                        <el-radio :label="1">
                            <el-date-picker
                                v-model="addForm.ExamBeginTime"
                                type="datetime"
                                placeholder="选择日期时间"
                                :picker-options="pickerOptions"
                                :disabled='ExamBeginTimeDisable'>
                            </el-date-picker>
                        </el-radio>
                        
                    </el-radio-group>
                </el-form-item>
                <!-- 考试结束时间 -->
                <el-form-item label="考试结束时间：" prop="ExamEndTime">
                    <el-radio-group v-model="ExamEndTimeOptionRes" @change='ExamEndTimeChoice'>
                        <el-radio :label="2">不限制时间</el-radio>
                        <el-radio :label="1">
                            <el-date-picker
                                v-model="addForm.ExamEndTime"
                                type="datetime"
                                placeholder="选择日期时间"
                                :picker-options="pickerOptions"
                                :disabled='ExamEndTimeDisable'>
                            </el-date-picker>
                        </el-radio>
                        
                    </el-radio-group>
                </el-form-item>
                <el-form-item  label='试卷名称：' prop='ExamPaperInfoID'>
                  <el-select v-model="addForm.ExamPaperInfoId" placeholder="请选择" @change='examChange'>
                    <template v-for="item in options1">
                      <el-option
                          v-if='item.KeepOnFile == false'
                          :key="item.ID"
                          :label="item.PaperName"
                          :value="item.ID">
                      </el-option>
                    </template>
                    <!-- 分页 -->
                    <el-pagination
                        :hide-on-single-page="testPaperIsPagination"
                        :total="pagesOpt1.total"
                        layout="prev, pager, next"
                        @current-change="pages1Change">
                    </el-pagination>
                  </el-select>
                </el-form-item>
<!--                <el-form-item  label='选择证书：' prop='CertificateDesignID'>-->
<!--                  <el-select v-model="addForm.CertificateDesignId" placeholder="请选择" @change='CertificateChange'>-->
<!--                    <el-option-->
<!--                        v-for="item in options2"-->
<!--                        :key="item.ID"-->
<!--                        :label="item.CertificateName"-->
<!--                        :value="item.ID">-->
<!--                    </el-option>-->
<!--                    &lt;!&ndash; 分页 &ndash;&gt;-->
<!--                    <el-pagination-->
<!--                        :hide-on-single-page="certificateIsPagination"-->
<!--                        :total="pagesOpt2.total"-->
<!--                        layout="prev, pager, next"-->
<!--                        @current-change="pages2Change">-->
<!--                    </el-pagination>-->
<!--                  </el-select>-->
<!--                </el-form-item>-->
<!--                <el-form-item label="考试地点：" prop="examPlace">-->
<!--                    <el-input v-model="addForm.examPlace"></el-input>-->
<!--                </el-form-item>-->
<!--                <el-form-item label="背景图片：" prop="backgroundPic">-->
<!--                    <el-upload-->
<!--                    :action="uploadImagesUrl"-->
<!--                    :headers="headers"-->
<!--                    ref="upload"-->
<!--                    :before-upload="beforeUpload"-->
<!--                    :on-success="handle_success1"-->
<!--                    :show-file-list="false"-->
<!--                    list-type="picture-card"-->
<!--                    :file-list="fileList1"-->
<!--                    >-->
<!--                        <img v-if="SignetWebImgUrl" :src="SignetWebImgUrl" class="avatar" />-->
<!--                        <i v-else class="el-icon-plus"></i>-->
<!--                    </el-upload>-->
<!--                    <div class="imgSpan2">只能上传jpg/png格式图片</div>-->
<!--                </el-form-item>-->
            </el-form>
            <span slot="footer" class="dialog-footer">
                <el-button @click="addVisible = false">取 消</el-button>
                <el-button type="primary" @click.native="addFormSubmit">确 定</el-button>
            </span>
       </el-dialog>
       <!-- 编辑 -->
       <el-dialog
            title="编辑项目"
            :visible.sync="editVisible"
            :close-on-click-modal="false"
            width="50%"
        >
            <el-form :model="editForm" label-width="160px" ref="editFormRef" :rules="editFormRules">
                <el-form-item label="项目编号：" prop="projectNumber">
                    <el-input v-model="editForm.projectNumber" :disabled='true'></el-input>
                </el-form-item>
                <el-form-item label="项目名称：" prop="projectName">
                    <el-input v-model="editForm.projectName" :disabled='true'></el-input>
                </el-form-item>
                <el-form-item label="项目介绍：" prop="projectNotes">
                    <el-input v-model="editForm.projectNotes" type="textarea"></el-input>
                </el-form-item>
<!--                <el-form-item label="考试流程：" prop="examProcedure">-->
<!--                    <el-input v-model="editForm.examProcedure" type="textarea"></el-input>-->
<!--                </el-form-item>-->
                <el-form-item label='报名开始时间：' prop='EnrollBeginTime'>
                    <el-radio-group v-model="editEnrollBeginTimeOptionRes" @change='editEnrollBeginTimeChoice' >
                        <el-radio :label="2">不限制时间</el-radio>
                        <el-radio :label="1">
                            <el-date-picker
                                v-model="editForm.EnrollBeginTime"
                                type="datetime"
                                placeholder="选择日期时间"
                                :picker-options="pickerOptions"
                                :disabled='EnrollBeginTimeDisable'
                               >
                            </el-date-picker>
                        </el-radio>
                        
                    </el-radio-group>
                </el-form-item>
                <el-form-item label='报名结束时间：' prop='EnrollEndTime'>
                    <el-radio-group v-model="editEnrollEndTimeOptionRes" @change='editEnrollEndTimeChoice'>
                        <el-radio :label="2">不限制时间</el-radio>
                        <el-radio :label="1">
                            <el-date-picker
                                v-model="editForm.EnrollEndTime"
                                type="datetime"
                                placeholder="选择日期时间"
                                :picker-options="pickerOptions"
                                :disabled='EnrollEndTimeDisable'>
                            </el-date-picker>
                        </el-radio>
                        
                    </el-radio-group>
                </el-form-item>
                <el-form-item label="考试开始时间：" prop="ExamBeginTime">
                   <el-radio-group v-model="editExamBeginTimeOptionRes" @change='editExamBeginTimeChoice' >
                        <el-radio :label="2">不限制时间</el-radio>
                        <el-radio :label="1">
                            <el-date-picker
                                v-model="editForm.ExamBeginTime"
                                type="datetime"
                                placeholder="选择日期时间"
                                :picker-options="pickerOptions"
                                :disabled='ExamBeginTimeDisable'>
                            </el-date-picker>
                        </el-radio>
                        
                    </el-radio-group>
                </el-form-item>
                <el-form-item label="考试结束时间：" prop="ExamEndTime">
                    <el-radio-group v-model="editExamEndTimeOptionRes" @change='editExamEndTimeChoice'>
                        <el-radio :label="2">不限制时间</el-radio>
                        <el-radio :label="1">
                            <el-date-picker
                                v-model="editForm.ExamEndTime"
                                type="datetime"
                                placeholder="选择日期时间"
                                :picker-options="pickerOptions"
                                :disabled='ExamEndTimeDisable'>
                            </el-date-picker>
                        </el-radio>
                        
                    </el-radio-group>
                </el-form-item>
                <el-form-item  label='试卷名称：' prop='ExamPaperInfoID'>
                  <el-select v-model="exam.ExamPaperInfoID" placeholder="请选择" @change='examChange'>
                    <template v-for="item in options1">
                      <el-option
                          v-if='item.KeepOnFile == false'
                          :key="item.ID"
                          :label="item.PaperName"
                          :value="item.ID">
                      </el-option>
                    </template>
                    <!-- 分页 -->
                    <el-pagination
                        :hide-on-single-page="testPaperIsPagination"
                        :total="pagesOpt1.total"
                        layout="prev, pager, next"
                        @current-change="pages1Change">
                    </el-pagination>
                  </el-select>
                </el-form-item>
<!--                <el-form-item  label='选择证书：' prop='CertificateDesignID'>-->
<!--                  <el-select v-model="exam.CertificateDesignID" placeholder="请选择" @change='CertificateChange'>-->
<!--                    <el-option-->
<!--                        v-for="item in options2"-->
<!--                        :key="item.ID"-->
<!--                        :label="item.CertificateName"-->
<!--                        :value="item.ID">-->
<!--                    </el-option>-->
<!--                    &lt;!&ndash; 分页 &ndash;&gt;-->
<!--                    <el-pagination-->
<!--                        :hide-on-single-page="certificateIsPagination"-->
<!--                        :total="pagesOpt2.total"-->
<!--                        layout="prev, pager, next"-->
<!--                        @current-change="pages2Change">-->
<!--                    </el-pagination>-->
<!--                  </el-select>-->
<!--                </el-form-item>-->
<!--                <el-form-item label="考试地点：" prop="examPlace">-->
<!--                    <el-input v-model="editForm.examPlace"></el-input>-->
<!--                </el-form-item>-->
<!--                <el-form-item label="背景图片：" prop="backgroundPic">-->
<!--                    <el-upload-->
<!--                    :action="uploadImagesUrl"-->
<!--                    :headers="headers"-->
<!--                    ref="upload"-->
<!--                    :before-upload="beforeUpload"-->
<!--                    :on-success="handle_success1"-->
<!--                    :show-file-list="false"-->
<!--                    list-type="picture-card"-->
<!--                    :file-list="fileList1"-->
<!--                    >-->
<!--                        <img v-if="SignetWebImgUrl" :src="SignetWebImgUrl" class="avatar" />-->
<!--                        <i v-else class="el-icon-plus"></i>-->
<!--                    </el-upload>-->
<!--                    <div class="imgSpan2">只能上传jpg/png格式图片</div>-->
<!--                </el-form-item>-->
            </el-form>
            <span slot="footer" class="dialog-footer">
                <el-button @click="editVisible = false">取 消</el-button>
                <el-button type="primary" @click.native="editFormSubmit">确 定</el-button>
            </span>
       </el-dialog>
       <!-- 发布 -->
       <el-dialog
            title="发布项目"
            :visible.sync="releaseVisible"
            width="50%"
            :close-on-click-modal="false"
            @close='cleanClose'>
            <div class="releaseBox" v-if='currentRow'>
                <div class="releaseRow">
                    <span class="releaseLabel">项目编号：</span>
                    <p class="releaseContent" >{{currentRow.ExamProjectNumber}}</p>
                </div>
                <div class="releaseRow">
                    <span class="releaseLabel">项目名称：</span>
                    <p class="releaseContent" >{{currentRow.ExamProjectName}}</p>
                </div>
                <div class="releaseRow">
                    <span class="releaseLabel">项目介绍：</span>
                    <p class="releaseContent" >{{currentRow.ExamProjectNotes}}</p>
                </div>
                <div class="releaseRow">
                    <span class="releaseLabel">考试流程：</span>
                    <p class="releaseContent" >{{currentRow.ExamProcedure}}</p>
                </div>
                <div class="releaseRow">
                    <span class="releaseLabel">报名时间：</span>
                    <p class="releaseContent" >{{currentRow.EnrollBeginTime}} - {{currentRow.EnrollEndTime}}</p>
                </div>
                <div class="releaseRow">
                    <span class="releaseLabel">考试时间：</span>
                    <p class="releaseContent" >{{currentRow.ExamBeginTime}} - {{currentRow.ExamEndTime}}</p>
                </div>
                <div class="releaseRow">
                    <span class="releaseLabel">考试地点：</span>
                    <p class="releaseContent" >{{currentRow.ExamPlace}}</p>
                </div>
                <div class="releaseRow">
                    <span class="releaseLabel">背景图片：</span>
                    <img style="width:100px;height:100px;" :src="currentRow.BackgroundImgUrl" alt="">
                </div>
                <el-divider></el-divider>
                <el-form :model='exam' label-width='130px' ref='examRef' :rules='examRules'>
                    <el-form-item  label='试卷名称：' prop='ExamPaperInfoID'>
                        <el-select v-model="exam.ExamPaperInfoID" placeholder="请选择" @change='examChange'>
                            <template v-for="item in options1">
                                <el-option  
                                    v-if='item.KeepOnFile == false'                             
                                    :key="item.ID"
                                    :label="item.PaperName"
                                    :value="item.ID">
                                </el-option>
                            </template>
                            <!-- 分页 -->
                            <el-pagination
                                :hide-on-single-page="testPaperIsPagination"
                                :total="pagesOpt1.total"
                                layout="prev, pager, next"
                                @current-change="pages1Change">
                            </el-pagination>
                        </el-select>
                    </el-form-item>
                    <el-form-item  label='选择证书：' prop='CertificateDesignID'>
                        <el-select v-model="exam.CertificateDesignID" placeholder="请选择" @change='CertificateChange'>
                            <el-option
                            v-for="item in options2"
                            :key="item.ID"
                            :label="item.CertificateName"
                            :value="item.ID">
                            </el-option>
                             <!-- 分页 -->
                            <el-pagination
                                :hide-on-single-page="certificateIsPagination"
                                :total="pagesOpt2.total"
                                layout="prev, pager, next"
                                @current-change="pages2Change">
                            </el-pagination>
                        </el-select>
                    </el-form-item>
                    <el-form-item label='考试费用：' prop='EnrollFee'>
                        <el-input v-model='exam.EnrollFee'></el-input>
                    </el-form-item>
                </el-form>
                <!-- 审批 -->
                <el-form  :model="approveForm" label-width="130px" label-position="center" ref="approveFormRef" :rules="approveFormRules">
                    <el-form-item label="审批记录名称：" prop="recordName">
                    <el-input v-model="approveForm.recordName"></el-input>
                    </el-form-item>
                    <el-form-item label="审批流程：" prop="approvalProcessID"> 
                    <el-select v-model="approveForm.approvalProcessID">
                        <template  v-for="item in dataPer" >
                            <!-- <el-option :key="item.index" :label="item.ProcessName" :value="item.ID" :disabled='item.ProcessType !== 2'></el-option> -->
                            <el-option :key="item.index" :label="item.ProcessName" :value="item.ID"></el-option>
                        </template>
                        <el-pagination
                        :hide-on-single-page="perValue"
                        :total="perPages"
                        layout="prev, pager, next"
                        @current-change="changePage">
                    </el-pagination>
                    </el-select>
                    </el-form-item>
                    <el-form-item label="说明：" prop="remarks">
                    <el-input v-model="approveForm.remarks"></el-input>
                    </el-form-item>
                </el-form>
            </div>
            <span slot="footer" class="dialog-footer">
                <el-button @click="releaseVisible = false">取 消</el-button>
                <el-button type="primary" @click="releaseSubmit(currentRow.ID)">确 定</el-button>
            </span>
       </el-dialog>
  </div>
</template>

<script>
import util from '../../../util/date';
import { getButtonList } from "@/promissionRouter";
import Toolbar from '@/components/Toolbar'
import Qs from 'qs'
import api from '@/api'
import {getExamProjectInfoListPage,addExamProjectInfo,deleteExamProjectInfo,publishExamProjectInfo,cancelExamProjectInfo,getApprovalProcessListPage,updateExamProjectInfo,getExaminationPaperListPage,getCertificateInfoListPage} from '@/api/api'
export default {
    components:{Toolbar},
    data(){
    //   新增时间验证
      var checkEnrollBeginTime = (rule,value,callback) => {
          if(this.EnrollBeginTimeOptionRes == 2){
              return callback()
          }else{
              if(this.addForm.EnrollEndTime!== '' && new Date(this.addForm.EnrollEndTime).getTime() >= new Date(value).getTime()){
                  return callback()
              }else{return callback('报名结束时间必须在报名开始时间之后！')}
          }
      }
      var checkEnrollEndTime = (rule,value,callback) => {
          if(this.EnrollEndTimeOptionRes == 2){
              return callback()
          }else{
              if(this.addForm.EnrollBeginTime!== '' && new Date(this.addForm.EnrollBeginTime).getTime() <= new Date(value).getTime()){
                  return callback()
              }else{return callback('报名结束时间必须在报名开始时间之后！')}
          }
      }
      var checkExamBeginTime = (rule,value,callback) => {
          if(this.ExamBeginTimeOptionRes == 2){
              return callback()
          }else{
              if(this.addForm.ExamEndTime!== '' && new Date(this.addForm.ExamEndTime).getTime() >= new Date(value).getTime()){
                  return callback()
              }else{return callback('考试结束时间必须在考试开始时间之后！')}
          }
      }
      var checkExamEndTime = (rule,value,callback) => {
          if(this.ExamEndTimeOptionRes == 2){
              return callback()
          }else{
              if(this.addForm.ExamBeginTime!== '' && new Date(this.addForm.ExamBeginTime).getTime() <= new Date(value).getTime()){
                  return callback()
              }else{return callback('考试结束时间必须在考试开始时间之后！')}
          }
      }
    //   编辑时间验证
      var editcheckEnrollBeginTime = (rule,value,callback) => {
          if(this.EnrollBeginTimeOptionRes == 2){
              return callback()
          }else{
              if(this.editForm.EnrollEndTime!== '' && new Date(this.editForm.EnrollEndTime).getTime() >= new Date(value).getTime()){
                  return callback()
              }else{return callback('报名结束时间必须在报名开始时间之后！')}
          }
      }
      var editcheckEnrollEndTime = (rule,value,callback) => {
          if(this.EnrollEndTimeOptionRes == 2){
              return callback()
          }else{
              if(this.editForm.EnrollBeginTime!== '' && new Date(this.editForm.EnrollBeginTime).getTime() <= new Date(value).getTime()){
                  return callback()
              }else{return callback('报名结束时间必须在报名开始时间之后！')}
          }
      }
      var editcheckExamBeginTime = (rule,value,callback) => {
          if(this.ExamBeginTimeOptionRes == 2){
              return callback()
          }else{
              if(this.editForm.ExamEndTime!== '' && new Date(this.editForm.ExamEndTime).getTime() >= new Date(value).getTime()){
                  return callback()
              }else{return callback('考试结束时间必须在考试开始时间之后！')}
          }
      }
      var editcheckExamEndTime = (rule,value,callback) => {
          if(this.ExamEndTimeOptionRes == 2){
              return callback()
          }else{
              if(this.editForm.ExamBeginTime!== '' && new Date(this.editForm.ExamBeginTime).getTime() <= new Date(value).getTime()){
                  return callback()
              }else{return callback('考试结束时间必须在考试开始时间之后！')}
          }
      }
      var checkEnrollFee = (rule,value,callback) => {
          var reg = /^\d+(\.\d{1,2})?$/
          if(reg.test(value)){
              return callback()
          }
          callback(new Error('必须为数字，最多精确到两位小数'))
      }
      var checkNum = (rule,value,callback) => {
        var reg = /^[0-9]*$/
        if(reg.test(value)){
            return callback()
        }
        callback(new Error('必须为数字'))
      }
      return {
        uploadImagesUrl: '',
        listLoading: false,
        // 筛选条件
        filter:{
            status:'',
            ExamProjectName:''             
        },
        optionsStatus:[{value:0,name:'未发布'},{value:1,name:'发布中'},{value:2,name:'已发布'}],
        // 增删改查按钮
        buttonList:[],
        tableData:[],
        //分页参数
        pageBarParams:{
            pageIndex:1,
            pageSize:20,
            DataCount:0,
            pageArr:[10, 20, 30, 40]
        }, 
        currentRow:null, //当前行数据
        addVisible:false, //新增隐藏与显示
        editVisible:false, //编辑隐藏与显示
        releaseVisible:false, //发布隐藏与显示
        // 新增表单数据
        addForm:{
            projectNumber:'',
            projectName:'',
            projectNotes:'',
            examProcedure:'',
            EnrollBeginTime:new Date(2000,0,1,0,0).toString(),
            EnrollEndTime:new Date(2099,0,1,0,0).toString(),
            ExamBeginTime:new Date(2000,0,1,0,0).toString(),
            ExamEndTime:new Date(2099,0,1,0,0).toString(),
            examPlace:'',
            backgroundPic: '',
            ExamPaperInfoId: '',
            CertificateDesignId: ''

        },
        // 编辑表单数据
        editForm:{
            projectNumber:'',
            projectName:'',
            projectNotes:'',
            examProcedure:'',
            EnrollBeginTime:'',
            EnrollEndTime:'',
            ExamBeginTime:'',
            ExamEndTime:'',
            examPlace:'',
            status:null,
            organizationInfoID:null,
            id:null,
            backgroundPic: '',
        },
        // 新增表单验证规则
        addFormRules:{
            projectNumber:[
                {required:true,message:'请输入数字格式项目编号',trigger:'blur'},
                {validator:checkNum,trigger:'blur'}
            ],
            projectName:[{required:true,message:'请输入项目名称',trigger:'blur'}],
            // EnrollBeginTime:[
            //     {validator:checkEnrollBeginTime,trigger:'blur',required:true}
            // ],
            // EnrollEndTime:[
            //     {validator:checkEnrollEndTime,trigger:'blur',required:true}
            // ],
            // ExamBeginTime:[
            //     {validator:checkExamBeginTime,trigger:'blur',required:true}
            // ],
            // ExamEndTime:[
            //     {validator:checkExamEndTime,trigger:'blur',required:true}
            // ],
            // examPlace:[{required:true,message:'请输入考试地点',trigger:'blur'}],
            // backgroundPic:[{required:true,message:'请上传背景图片',trigger:'blur'}],
            projectNotes:[{message:'请输入项目介绍'}],
            // examProcedure:[{message:'请输入考试流程'}]
        },
        // 编辑表单验证规则
        editFormRules:{
            projectNumber:[
                {required:true,message:'请输入数字格式项目编号',trigger:'blur'},
                {validator:checkNum,trigger:'blur'}
            ],
            projectName:[{required:true,message:'请输入项目名称',trigger:'blur'}],
            // EnrollBeginTime:[
            //     {validator:editcheckEnrollBeginTime,trigger:'blur',required:true}
            // ],
            // EnrollEndTime:[
            //     {validator:editcheckEnrollEndTime,trigger:'blur',required:true}
            // ],
            // ExamBeginTime:[
            //     {validator:editcheckExamBeginTime,trigger:'blur',required:true}
            // ],
            // ExamEndTime:[
            //     {validator:editcheckExamEndTime,trigger:'blur',required:true}
            // ],
            // examPlace:[{required:true,message:'请输入考试地点',trigger:'blur'}],
            // backgroundPic:[{required:true,message:'请上传背景图片',trigger:'blur'}],
            projectNotes:[{message:'请输入项目介绍'}],
            // examProcedure:[{message:'请输入考试流程'}]
        },
        // 发布中表单其他数据
        exam:{
            ExamPaperInfoID:null,
            CertificateDesignID:null,
            EnrollFee:null,
        },
        examRules:{
            ExamPaperInfoID:[{required:true,message:'输入试卷名称',trigger:'blur'}],
            CertificateDesignID:[{required:true,message:'请选择证书',trigger:'blur'}],
            EnrollFee:[
                {required:true,message:'请输入报名费',trigger:'blur'},
                {validator:checkEnrollFee,trigger:'blur'}
            ]
        },
        // 审批数据
        approveForm: {
            recordName: '',
            approvalProcessID: '',
            remarks: ''
        },
        approveFormRules:{
            recordName:[{required:true,message:'请输入编号',trigger:'blur'}],
            approvalProcessID:[{required:true,message:'请输入题目',trigger:'blur'}],
            remarks:[{message:'请输入说明',trigger:'blur'}]
        },
        dataPer:[],
        perValue: false,
        perPages:0,
        perPagesIndex:1,
        isDisabled: false,
        isChange: 0,
        // 发布所需参数
        params:{
            ExamProjectInfoID:null, 
            CertificateDesignID:null,
            EnrollFee:null,
            ExamPaperInfoID:null,
            ApprovalRecord:{}
        },
        // 试卷的选项值
        options1:[],
        // 试卷选项值的分页参数
        pagesOpt1:{
            pageIndex:1,
            pageSize:10,
            total:null,
        },
        // // 证书的选项值
        options2:[],
        // 试卷选项值的分页参数
        pagesOpt2:{
            pageIndex:1,
            pageSize:10,
            total:null,
        },
        // 图片
        imageUrlDataStamp: '',
        SignetWebImgUrl: '',
        fileList1:[],
        radio:1,
        // 设置当天之前时间禁用
        pickerOptions:{
            // disabledDate(time){
			// 	return time.getTime() < (Date.now()-(24 * 60 * 60 * 1000));
			// }
        },
        // 新增单选框结果
        EnrollBeginTimeOptionRes:2,  // 报名开始时间
        EnrollEndTimeOptionRes:2,  // 报名结束时间
        ExamBeginTimeOptionRes:2,  // 考试开始时间
        ExamEndTimeOptionRes:2,  // 考试结束时间
        // 编辑单选框结果
        editEnrollBeginTimeOptionRes:null,
        editEnrollEndTimeOptionRes:null,
        editExamBeginTimeOptionRes:null,
        editExamEndTimeOptionRes:null,
        // 设置时间是否禁用
        EnrollBeginTimeDisable:true,
        EnrollEndTimeDisable:true,
        ExamBeginTimeDisable:true,
        ExamEndTimeDisable:true,

        // 控制试卷是否分页
        testPaperIsPagination:true,
        // 控制证书是否分页
        certificateIsPagination:true
      }
    },
    methods:{
        // 时间单选选择(新增)
        EnrollBeginTimeChoice(value){
            if(value == 1){
                this.EnrollBeginTimeOptionRes = value
                this.addForm.EnrollBeginTime = new Date().toString()
                this.EnrollBeginTimeDisable = false
            }           
            if(value == 2){
                this.EnrollBeginTimeOptionRes = value
                this.addForm.EnrollBeginTime = new Date(2000,0,1,0,0).toString()
                this.EnrollBeginTimeDisable = true
            }      
        },
        EnrollEndTimeChoice(value){
            if(value == 1){
                this.EnrollEndTimeOptionRes = value
                this.addForm.EnrollEndTime = new Date().toString()
                this.EnrollEndTimeDisable = false
            }          
            if(value == 2){
                this.EnrollEndTimeOptionRes = value
                this.addForm.EnrollEndTime = new Date(2099,0,1,0,0).toString()
                this.EnrollEndTimeDisable = true
            }        
        },
        ExamBeginTimeChoice(value){
            if(value == 1){
                this.ExamBeginTimeOptionRes = value
                this.addForm.ExamBeginTime = new Date().toString()
                this.ExamBeginTimeDisable = false
            }           
            if(value == 2){
                this.ExamBeginTimeOptionRes = value
                this.addForm.ExamBeginTime = new Date(2000,0,1,0,0).toString() 
                this.ExamBeginTimeDisable = true
            }
        },
        ExamEndTimeChoice(value){
            if(value == 1){
                this.ExamEndTimeOptionRes = value
                this.addForm.ExamEndTime = new Date().toString()
                this.ExamEndTimeDisable = false
            }          
            if(value == 2){
                this.ExamEndTimeOptionRes = value
                this.addForm.ExamEndTime = new Date(2099,0,1,0,0).toString()
                this.ExamEndTimeDisable = true
            } 
        },
        // 时间改变(编辑)
        editEnrollBeginTimeChoice(value){
            if(value == 1){
                this.EnrollBeginTimeOptionRes = value
                this.editForm.EnrollBeginTime = new Date().toString()
                this.EnrollBeginTimeDisable = false
            }           
            if(value == 2){
                this.EnrollBeginTimeOptionRes = value
                this.editForm.EnrollBeginTime = new Date(2000,0,1,0,0).toString()
                this.EnrollBeginTimeDisable = true
            }      
        },
        editEnrollEndTimeChoice(value){
            if(value == 1){
                this.EnrollEndTimeOptionRes = value
                this.editForm.EnrollEndTime = new Date().toString()
                this.EnrollEndTimeDisable = false
            }          
            if(value == 2){
                this.EnrollEndTimeOptionRes = value
                this.editForm.EnrollEndTime = new Date(2099,0,1,0,0).toString()
                this.EnrollEndTimeDisable = true
            }  
        },
        editExamBeginTimeChoice(value){
            if(value == 1){
                this.ExamBeginTimeOptionRes = value
                this.editForm.ExamBeginTime = new Date().toString()
                this.ExamBeginTimeDisable = false
            }           
            if(value == 2){
                this.ExamBeginTimeOptionRes = value
                this.editForm.ExamBeginTime = new Date(2000,0,1,0,0).toString() 
                this.ExamBeginTimeDisable = true
            }
        },
        editExamEndTimeChoice(value){
            if(value == 1){
                this.ExamEndTimeOptionRes = value
                this.editForm.ExamEndTime = new Date().toString()
                this.ExamEndTimeDisable = false
            }          
            if(value == 2){
                this.ExamEndTimeOptionRes = value
                this.editForm.ExamEndTime = new Date(2099,0,1,0,0).toString()
                this.ExamEndTimeDisable = true
            } 
        },
        callFunction(item) {
          this[item.Func].apply(this, item);
        },
        handleCurrentRow(current){
            this.currentRow = current
        },
        // 分页
        handleSizeChange(size){
            this.pageBarParams.pageSize = size        
            this.getData()
        },
        handleCurrentChange(current){
            // if(this.filter.status != '' || this.filter.ExamProjectName != ''){
            //     this.pageBarParams.pageIndex = 1
            // }else{
                this.pageBarParams.pageIndex = current
            // }     
            this.getData()
        },
        // 查询 
        getExamProjectInfoListPage(){
            this.pageBarParams.pageIndex = 1
            this.getData()
        },
        // 新增
        handleAdd(){
            this.addVisible = true
            this.SignetWebImgUrl = ''
            this.EnrollBeginTimeOptionRes = 2
            this.EnrollEndTimeOptionRes = 2
            this.ExamBeginTimeOptionRes = 2
            this.ExamEndTimeOptionRes = 2
            this.EnrollBeginTimeDisable = true
            this.EnrollEndTimeDisable = true
            this.ExamBeginTimeDisable = true
            this.ExamEndTimeDisable = true
            this.addForm = {
                projectNumber:'',
                projectName:'',
                projectNotes:'',
                examProcedure:'',
                EnrollBeginTime:new Date(2000,0,1,0,0).toString(),
                EnrollEndTime:new Date(2099,0,1,0,0).toString(),
                ExamBeginTime:new Date(2000,0,1,0,0).toString(),
                ExamEndTime:new Date(2099,0,1,0,0).toString(),
                examPlace:'',
                backgroundPic: '',
            }
            this.getExam()
        },
        // 编辑
        handleEdit(){
            var row = this.currentRow
            if(row == null){
                this.$message({type:'error',message:'请选择要编辑的一行数据'})
                return
            }
            if(row.EnrollBeginTime !== this.fixedStartTime.toString()){
                this.editEnrollBeginTimeOptionRes = 1
                this.EnrollBeginTimeDisable = false
            }else{
                this.editEnrollBeginTimeOptionRes = 2
                this.EnrollBeginTimeDisable = true
            }
            if(row.EnrollEndTime !== this.fixedEndTime.toString()){
                this.editEnrollEndTimeOptionRes = 1
                this.EnrollEndTimeDisable = false
            }else{
                this.editEnrollEndTimeOptionRes = 2
                this.EnrollEndTimeDisable = true
            }
            if(row.ExamBeginTime !== this.fixedStartTime.toString()){
                this.editExamBeginTimeOptionRes = 1
                this.ExamBeginTimeDisable = false
            }else{
                this.editExamBeginTimeOptionRes = 2
                this.ExamBeginTimeDisable = true
            }
            if(row.ExamEndTime !==this.fixedEndTime.toString()){
                this.editExamEndTimeOptionRes = 1
                this.ExamEndTimeDisable = false
            }else{
                this.editExamEndTimeOptionRes = 2
                this.ExamEndTimeDisable = true
            }
            if(row.Status == 2 || row.Status == 1){
                this.editVisible = false
                this.$message({type:'error',message:'不能再编辑了'})
            }else{
                this.editForm.projectNumber = row.ExamProjectNumber
                this.editForm.projectName = row.ExamProjectName
                this.editForm.projectNotes = row.ExamProjectNotes
                this.editForm.examProcedure = row.ExamProcedure
                this.editForm.EnrollBeginTime = row.EnrollBeginTime 
                this.editForm.EnrollEndTime = row.EnrollEndTime
                this.editForm.ExamBeginTime = row.ExamBeginTime
                this.editForm.ExamEndTime = row.ExamEndTime
                this.editForm.examPlace = row.ExamPlace
                this.editForm.status = row.Status
                this.editForm.organizationInfoID = row.OrganizationInfoID
                this.editForm.id = row.ID
                this.editForm.backgroundPic = 1
                this.SignetWebImgUrl = row.BackgroundImgUrl
                this.editVisible = true
                this.imageUrlDataStamp = {
                    webupurl: row.BackgroundImgUrl
                }
            }
        },
        // 删除
        handleDel(){
            var row = this.currentRow
            if(row == null){
                this.$message({type:'error',message:'请选择要删除的一行数据'})
                return
            }
            if(row.Status == 0){
                this.$confirm('确定要删除该行数据吗?','提示',{confirmButtonText:'确定',cancelButtonText:'取消',type:'warning'}).then(() => {
                    deleteExamProjectInfo({Id:row.ID}).then(res => {
                        if (util.isEmt.format(res)) {
                            return;
                        }
                        if(res.data.Success){
                            this.$message({type:'success',message:res.data.Message})
                            this.getData()
                        }else{
                            this.$message({type:'error',message:res.data.Message})
                        }                  
                    })               
                }).catch(() => {})
            }else if(row.Status == 1 || row.Status == 2){
                this.$message.error('当前为发布状态，不能删除')
            }
            
        },
        // 发布
        publishExamProjectInfo(){
            var row = this.currentRow
            if(row == null){
                this.$message.error('请选择要发布的一行数据')
                return
            }   
            if(row.Status === 2){
                this.releaseVisible = false
                this.$message.error('该项目已发布过了')
            }else if(row.Status === 1){
                this.releaseVisible = false
                this.$message.error('该项目在发布中')
            } else{
                this.$confirm('是否要发布?', '提示', {
                  confirmButtonText: '确定',
                  cancelButtonText: '取消',
                  type: 'warning'
                }).then(() => {
                    var params = {
                      Id: row.ID
                    }
                    publishExamProjectInfo(params).then(res => {
                      if (res.data.Success) {
                        this.$message({
                          type: 'success',
                          message: res.data.Message
                        })
                        this.getData()
                      } else {
                        this.$message({
                          type: 'error',
                          message: res.data.Message
                        })
                      }
                    })
                })
            }

        },
        // 发布清除表单项
        cleanClose(){
            this.$refs.approveFormRef.resetFields()
            this.$refs.examRef.resetFields()
        },
        // 是否限制时间
        LimitTime(value){
            setTimeout(() => {
                this.radio = value
            },600)
            
            if(this.radio == 1){
                this.addForm.signUpTime = [new Date(),new Date(2099,0,1,0,0)]
                this.addForm.examinationTime = [new Date(),new Date(2099,0,1,0,0)]               
            }
            if(this.radio == 2){
                this.addForm.signUpTime = []
                this.addForm.examinationTime = []            
            }
        },
        // 新增提交表单
        addFormSubmit(){
            this.$refs['addFormRef'].validate((valid) => {
                if(valid){
                    this.$confirm('确认要提交吗','提示',{}).then(() => {
                        this.addVisible = false
                        var params = {
                            ExamProjectNumber:this.addForm.projectNumber,
                            ExamProjectName:this.addForm.projectName,
                            ExamProjectNotes:this.addForm.projectNotes,
                            ExamProcedure:this.addForm.examProcedure,
                            EnrollBeginTime:this.format(this.gettime(this.rTime(new Date(this.addForm.EnrollBeginTime), "yyyy-MM-dd")),'yyyy-MM-dd HH:mm:ss'),
                            EnrollEndTime:this.format(this.gettime(this.rTime(new Date(this.addForm.EnrollEndTime), "yyyy-MM-dd")),'yyyy-MM-dd HH:mm:ss'),
                            ExamBeginTime:this.format(this.gettime(this.rTime(new Date(this.addForm.ExamBeginTime), "yyyy-MM-dd")),'yyyy-MM-dd HH:mm:ss'),
                            ExamEndTime:this.format(this.gettime(this.rTime(new Date(this.addForm.ExamEndTime), "yyyy-MM-dd")),'yyyy-MM-dd HH:mm:ss'),
                            ExamPlace:this.addForm.examPlace,
                            BackgroundImgUrl: this.imageUrlDataStamp.webupurl,
                            ExamPaperInfoId: this.addForm.ExamPaperInfoId,
                            CertificateDesignId: this.addForm.CertificateDesignId
                        }
                        addExamProjectInfo(params).then(res => { 
                            if(res.data.Success){
                                this.$message({
                                    type:'success',
                                    message:res.data.Message
                                })
                                this.getData()
                            }else{
                                this.$message({
                                    type:'error',
                                    message:res.data.Message
                                })
                            }
                        })
                    }).catch(() => {})
                }
            })
        },
        // 编辑提交表单
        editFormSubmit(){
            this.$refs['editFormRef'].validate((valid) => {
                if(valid){
                    this.$alert('确认提交吗?','提示',{}).then(() => {
                        this.editVisible = false
                        var params = {
                            ExamProjectNumber:this.editForm.projectNumber,
                            ExamProjectName:this.editForm.projectName,
                            ExamProjectNotes:this.editForm.projectNotes,
                            ExamProcedure:this.editForm.examProcedure,
                            EnrollBeginTime:this.format(this.gettime(this.rTime(new Date(this.editForm.EnrollBeginTime), "yyyy-MM-dd")),'yyyy-MM-dd HH:mm:ss'),                      
                            EnrollEndTime:this.format(this.gettime(this.rTime(new Date(this.editForm.EnrollEndTime), "yyyy-MM-dd")),'yyyy-MM-dd HH:mm:ss'),
                            ExamBeginTime:this.format(this.gettime(this.rTime(new Date(this.editForm.ExamBeginTime), "yyyy-MM-dd")),'yyyy-MM-dd HH:mm:ss'),
                            ExamEndTime:this.format(this.gettime(this.rTime(new Date(this.editForm.ExamEndTime), "yyyy-MM-dd")),'yyyy-MM-dd HH:mm:ss'),
                            ExamPlace:this.editForm.examPlace,
                            CertificateDesignName:this.editForm.certificateDesignName,
                            Status:this.editForm.status,
                            OrganizationInfoID:this.editForm.organizationInfoID,
                            BackgroundImgUrl:this.imageUrlDataStamp.webupurl,
                            Id:this.editForm.id
                        }
                        updateExamProjectInfo(params).then(res => {
                            if(res.data.Success){
                                 this.$message({
                                    type:'success',
                                    message:res.data.Message
                                })
                                this.editVisible = false
                                this.getData()
                            }else{
                                this.$message({
                                    type:'error',
                                    message:res.data.Message
                                })
                                this.editVisible = false
                            }
                        })
                    }).catch(() => {})
                }
            })
        }, 
        // 提交表单清空
        addDialogClose(){
            this.$refs.addFormRef.resetFields()
            this.SignetWebImgUrl = ''
        },
        // 发布提交表单
        releaseSubmit(id){
            this.params.EnrollFee = parseInt(this.exam.EnrollFee)
            this.params.CertificateDesignID = parseInt(this.exam.CertificateDesignID)
            this.$refs['approveFormRef'].validate((valid) => {
                if(valid){
                    this.params.ApprovalRecord = {
                        RecordName:this.approveForm.recordName,
                        Remarks:this.approveForm.remarks,
                        ApprovalProcessID:this.approveForm.approvalProcessID,
                        ID:id
                    }
                    this.$confirm('确认发布吗?','提示',{}).then(() => {
                        this.releaseVisible = false
                        publishExamProjectInfo(this.params).then(res => {                          
                            if(res.data.Success){
                                this.$message({
                                    message:res.data.Message,
                                    type:'success'
                                })
                                this.getData()
                                return
                            }
                            this.$message({
                                message:res.data.Message,
                                type:'error'
                            })
                        })
                    })
                }
            })
        },
        // 取消发布
        handleCancelExamProject(){
            var row = this.currentRow
            if(row == null){
                this.$message({
                    message:'请选择取消发布的一行数据',
                    type:'error'
                })
                return
            }
            if(row.Status == 2){
                this.$confirm('是否要取消发布?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                cancelExamProjectInfo({Id:row.ID}).then(res => {
                    if (util.isEmt.format(res)) {
                        return;
                    }
                    if(res.data.Success){
                        this.$message({
                            message:res.data.Message,
                            type:"success"
                        })
                        this.getData()
                        return
                    }
                    this.$message({
                        message:res.data.Message,
                        type:'error'
                    })
                })
            }).catch(err => {
                if(err == 'cancel'){
                    this.$message('已取消发布')
                }
            })
            }else if(row.Status == 1){
                this.$message({
                    message:'当前状态为发布中，不能取消发布',
                    type:'error'
                })
            }else if(row.Status == 0){
                this.$message.error('当前项目还未发布')
            }
            
        },
        // 获取考试项目分页列表
        getData(){
            this.listLoading = true
            var params = {
                pageIndex:this.pageBarParams.pageIndex,
                pageSize:this.pageBarParams.pageSize,
                status:this.filter.status,
                projectName:this.filter.ExamProjectName
            }
            getExamProjectInfoListPage(params).then(res => {
                if(res.data.Success){
                    var data = res.data.Response
                    for(var i = 0;i<data.Data.length;i++){
                        data.Data[i].EnrollBeginTime = this.format(this.gettime(this.rTime(new Date(data.Data[i].EnrollBeginTime), "yyyy-MM-dd")),'yyyy-MM-dd HH:mm:ss')
                        data.Data[i].EnrollEndTime = this.format(this.gettime(this.rTime(new Date(data.Data[i].EnrollEndTime), "yyyy-MM-dd")),'yyyy-MM-dd HH:mm:ss')
                        data.Data[i].ExamBeginTime = this.format(this.gettime(this.rTime(new Date(data.Data[i].ExamBeginTime), "yyyy-MM-dd")),'yyyy-MM-dd HH:mm:ss')
                        data.Data[i].ExamEndTime = this.format(this.gettime(this.rTime(new Date(data.Data[i].ExamEndTime), "yyyy-MM-dd")),'yyyy-MM-dd HH:mm:ss')
                    } 
                    this.tableData = data.Data
                    this.listLoading = false
                    this.pageBarParams.pageIndex = data.PageIndex   
                    this.pageBarParams.DataCount = data.DataCount 
                    if(data.PageSize > 0){
                    this.pageBarParams.pageSize = data.PageSize
                    }    
                    return 
                }else{
                    var _this = this
                    setTimeout(() => {
                        _this.listLoading = false
                    },2000)
                }
            })
        },
        // 搜索内容清除
        clearClose(){
            this.getData()
        },
        // 时间
        gettime(startTime){
            var starttimeHaoMiao = (new Date(startTime)).getTime() + 8 * 60 * 60 * 1000
            return starttimeHaoMiao
        },
        format(time, format){
            var t = new Date(time);
            var tf = function(i){return (i < 10 ? '0' : '') + i};
            return format.replace(/yyyy|MM|dd|HH|mm|ss/g, function(a){
                switch(a){
                    case 'yyyy':
                        return tf(t.getFullYear());
                        break;
                    case 'MM':
                        return tf(t.getMonth() + 1);
                        break;
                    case 'mm':
                        return tf(t.getMinutes());
                        break;
                    case 'dd':
                        return tf(t.getDate());
                        break;
                    case 'HH':
                        return tf(t.getHours());
                        break;
                    case 'ss':
                        return tf(t.getSeconds());
                        break;
                }
            })
        },
        // 审批流程相关函数
        // 获取列表
        getApprovalList() {
            var params = {
                pageIndex: this.perPagesIndex,
                pageSize: 10,
                processType: 2,
            }
            getApprovalProcessListPage(params).then(res => {
                var resData = res.data
                if(resData.Success){
                    var data = resData.Response.Data
                    var a = data.filter(item => item.ProcessType == 2)
                    this.dataPer = a
                // this.dataPer = resData.Response.Data
                    this.perPages = resData.Response.DataCount
                    }else{
                    this.$message({
                        message: resData.Message,
                        type: "error"
                    });
                }
            });
        },
        changePage(val) {
            this.perPagesIndex = val
            this.getApprovalList()
        },
        //时间格式化
        formatCreateTime: function(row, column) {
        return !row.CreateTime || row.CreateTime == ""
            ? ""
            : util.formatDate.format(new Date(row.CreateTime), "yyyy-MM-dd");
        },
        rTime(date) {
            var json_date = new Date(date).toJSON();
            return new Date(new Date(json_date) + 8 * 3600 * 1000).toISOString().replace(/T/g, ' ').replace(/\.[\d]{3}Z/, '') 
        },
        // 获取试卷值
        examChange(value){
            this.params.ExamPaperInfoID = value
        },
        // 获取证书值
        CertificateChange(value){
            this.exam.CertificateDesignID = value
        },
        // 调取接口，得到试卷名称及启用相关信息
        getExam(){
            var params = {
                pageIndex:this.pagesOpt1.pageIndex,
                pageSize:this.pagesOpt1.pageSize,
                // DataType: 2,
                keepOnFile: false,
                dataType: 1
            }
            getExaminationPaperListPage(params).then(res => {
                if(res.data.Success){
                    var data = res.data.Response.Data
                    this.options1 = data.filter(item => item.IsEnable == true)
                    var total = res.data.Response.DataCount
                    if(total >= 10){
                        this.testPaperIsPagination = false
                    }else{
                        this.testPaperIsPagination = true
                    }
                    this.pagesOpt1.total = total
                }else{
                    this.$message({
                        message:res.data.Message,
                        type:'error'
                    })
                }
            })
        },
        // 调取接口，得到证书名称
        getCertificate(){
            var params = {
                pageIndex:this.pagesOpt2.pageIndex,
                pageSize:this.pagesOpt2.pageSize,
            }
            getCertificateInfoListPage(params).then(res => {
                if(res.data.Success){
                    this.options2 = res.data.Response.Data
                    var total = res.data.Response.DataCount
                    if(total >= 10){
                        this.certificateIsPagination = false
                    }else{
                        this.certificateIsPagination = true
                    }
                    this.pagesOpt2.total = total
                }else{
                    this.$message({
                        message:res.data.Message,
                        type:'error'
                    })
                }
            })
        },
        // 选择试卷选项分页
        pages1Change(value){
            this.pagesOpt1.pageIndex = value
            this.getExam()
        },
        pages2Change(value){
            this.pagesOpt2.pageIndex = value
            this.getCertificate()
        },
        getToken() {
            return localStorage.getItem("Token")
        },
        // 图片上传之前函数
        beforeUpload(file) {
            const isLt2M = file.size / 1024 / 1024 > 2;
            var type = file.name.substring(file.name.lastIndexOf(".")+1);
            if (type == 'jpg' || type == 'png') {} else {
                this.$message.error('上传图片只能是 JPG/PNG 格式!');
                return false;
            }
            if (!isLt2M === false) {
                this.$message.error('上传图片大小不能超过 2MB!');
                return false
            }
        },
        // 图片上传成功
        handle_success1(res) {
            if(res.Success) {
                this.$message.success('图片上传成功')
                this.imageUrlDataStamp = res.Response
                this.SignetWebImgUrl = res.Response.weburl
                this.addForm.backgroundPic = 1
                this.editForm.backgroundPic = 1
            }else{
                this.$message.error(res.Message)
            }
		},
        // ////////////////
    },
    computed: {
        headers() {
            return{
                "Authorization": 'Bearer ' + this.getToken() // 直接从本地获取token就行
            }
        },
        fixedStartTime(){
            return this.format(this.gettime(this.rTime(new Date(2000,0,1,0,0), "yyyy-MM-dd")),'yyyy-MM-dd HH:mm:ss')
        },
        fixedEndTime(){
            return this.format(this.gettime(this.rTime(new Date(2099,0,1,0,0), "yyyy-MM-dd")),'yyyy-MM-dd HH:mm:ss')
        }
    },
    created(){
        this.getData()
        this.uploadImagesUrl = api.uploadImagesUrl
    },
    mounted(){
        let routers = window.localStorage.router
        ? JSON.parse(window.localStorage.router)
        : [];
        this.buttonList = getButtonList(this.$route.path, routers);
    },
}
</script>

<style lang="stylus" scoped>
.pageBar{
	height :60px;
}
    .releaseRow{
        display:flex!important;
        align-items:center;
    }
    .releaseContent{
        margin-top:16px!important;
        margin-left:5px!important;
        max-width 75%;
    }
    .avatar-uploader-icon {
        font-size: 28px;
        color: #8c939d;
        width: 178px;
        height: 178px;
        line-height: 178px;
        text-align: center;
    }
    .avatar {
        width: 100%;
        height: 100%;
        display: block;
    }
    .releaseLabel {
        width:120px;
        text-align:right;
        padding-right:5px!important;
    }
</style>