<template>
    <div>
        <!-- 顶部工具条 -->
        <el-col :span="24">
            <el-form :inline="true">
                <!-- <el-form-item label='关键字:'>
                    <el-input placeholder="批次名称" 
                        v-model="filters.searchKey"
                        clearable
                    >
                    </el-input>
                </el-form-item> -->
                <el-form-item label="批次查询:">
                    <el-select v-model="filters.examBatch" placeholder="请选择">
                        <el-option 
                            v-for="item in examBatchArr"
                            :key="item.ID"
                            :label="item.Name"
                            :value="item.ID"
                        >
                        </el-option>
                        <el-pagination
                            layout="prev, pager, next"
                            @current-change="currentChange"
                            :total="brandListPage.DataCount">
                        </el-pagination>
                    </el-select>
                </el-form-item>
                <!-- <el-form-item label='省：'>
                    <el-select v-model="filters.provinceID" clearable @change='selProvince' placeholder="请选择">
                        <el-option
                            v-for="item in provinceIDArr"
                            :key="item.ID"
                            :label="item.Name"
                            :value="item.ID"
                        >
                        </el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label='市：'>
                    <el-select v-model="filters.cityID" clearable  @change='selCity' placeholder="请选择">
                        <el-option
                            v-for="item in cityIDArr"
                            :key="item.ID"
                            :label="item.Name"
                            :value="item.ID"
                        >
                        </el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label='区/县：'>
                    <el-select v-model="filters.regionID" clearable placeholder="请选择">
                        <el-option
                            v-for="item in regionIDArr"
                            :key="item.ID"
                            :label="item.Name"
                            :value="item.ID"
                        >
                        </el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="岗位:" clearable placeholder="请选择岗位">
                    <el-select v-model="filters.station" >
                        <el-option 
                            v-for="item in stationArr"
                            :key="item.ID"
                            :label="item.Name"
                            :value="item.ID"
                        >
                        </el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="行业:" clearable placeholder="请选择行业">
                    <el-select v-model="filters.trade" >
                        <el-option 
                            v-for="item in tradeArr"
                            :key="item.ID"
                            :label="item.Name"
                            :value="item.ID"
                        >
                        </el-option>
                    </el-select>
                </el-form-item> -->
                <el-form-item>
                    <toolbar :buttonList="buttonList" @callFunction="callFunction"></toolbar>
                </el-form-item>
            </el-form>
        </el-col>
        <!-- 表格 -->
        <el-table 
            v-loading="tableLoading"
            :data="tableData" 
            style="width:100%" 
            border 
            ref="multipleTable"
            highlight-current-row 
            @current-change="handlerowChange"
            @selection-change="handleSelectChange"
        >
            <el-table-column type="selection" width="45"></el-table-column>
            <el-table-column label="批次名称" min-width="100" align="center" prop="ExamBatchPopulationName"></el-table-column>
            <el-table-column label="姓名" width="" align="center" prop="Name"></el-table-column>
            <el-table-column label="身份证号" min-width="100" align="center" prop="CardNo"></el-table-column>
            <el-table-column label="手机号" width="" align="center" prop="PhoneNumber"></el-table-column>
            <el-table-column  label="数据来源" width="120" align="center" prop="RequestSource">
                <!-- <template slot-scope="scope">
                    <el-tag :type="'success'" v-if="scope.row.QuestionsType == 1">iOS</el-tag>
                    <el-tag :type="'warning'" v-if="scope.row.QuestionsType == 2">Android</el-tag>
                </template> -->
            </el-table-column>
            <el-table-column  label="单位名称" min-width="100" align="center" prop="UnitName"></el-table-column>
            <el-table-column  label="单位所在省" min-width="110" align="center" prop="Province"></el-table-column>
            <el-table-column  label="单位所在市" min-width="110" align="center" prop="City"></el-table-column>
            <el-table-column  label="单位所在区" min-width="110" align="center" prop="Region"></el-table-column>
            <el-table-column  label="岗位名称" min-width="100" align="center" prop="StationName"></el-table-column>
            <el-table-column  label="行业名称" min-width="100" align="center" prop="TradeName"></el-table-column>
        </el-table>
        <!-- 分页 -->
        <el-col :span="24" class="pageBarContainer">
            <div>已选择<span class="selectNumCla">{{allSelect.length}}</span>条</div>
            <div>
                <el-pagination
                    small
                    :page-sizes="pages.pageArr"
                    :page-size="pages.pageSize"
                    :current-page="pages.pageIndex"
                    layout="total, sizes, prev, pager, next, jumper"
                    :total="pages.dataCount"
                    @size-change="handleSizeChange" 
                    @current-change="handleCurrentChange"
                    class="pageBar"       
                    >
                </el-pagination>
            </div>
        </el-col>
        <!-- 申请组织考试 -->
        <el-dialog
            title="填写考试时间"
            :visible.sync="sendMessageDialog"
            v-model="sendMessageDialog"
            :close-on-click-modal="false"
        >
            <el-form :model="sendMessageForm" :rules="sendMessageFormRules" ref="sendMessageFormRef" label-width="150px" enctype="multipart/form-data">
                <el-form-item label='考试截止时间:' prop='examTimer'>
                    <el-date-picker
                        v-model="sendMessageForm.examTimer"
                        type="date"
                        placeholder="选择日期"
                        style="width: 60%;"
                    >
                    </el-date-picker>
                </el-form-item>
                <!-- <el-form-item label='设置考试地点:' prop='examAddress'>
                    <el-input style="width: 60%;" placeholder="请输入考试地点" v-model='sendMessageForm.examAddress' clearable></el-input>
                </el-form-item> -->
            </el-form>
            <div style="width: 90%;margin: 0 auto;">
                <div class="messageTitle">
                    短信模板内容
                </div>
                <div class="messageContent">
                    <!-- 您好，您在社会消防安全教育培训管理系统上申请的免培考试已通过，请于<span v-if="DateTime == 'DateTime'">${ {{DateTime}} }</span><span v-if="DateTime != 'DateTime'"> {{DateTime}} </span>前往<span v-if="Address == 'Address'">${ {{Address}} }</span><span v-if="Address != 'Address'"> {{Address}} </span>参加集中考试！ -->
                    您好，您在消防考试宝上的免培考试申请已通过，请于<span v-if="DateTime == 'DateTime'">${ {{DateTime}} }</span><span v-if="DateTime != 'DateTime'"> {{DateTime}} </span>前登录“消防考试宝APP”进行考试!逾期将取消免培考试资格，需在线完成法定课时学习后才能够参加考试!
                </div>
            </div>
            <div slot="footer" class="dialog-footer">
                <el-button @click.native="sendMessageDialog = false">取消</el-button>
                <el-button type="primary" @click.native="sendMessageSubmit" :loading="sendMessageLoading">提交申请</el-button>
            </div>
        </el-dialog>
    </div>
</template>

<script>
import util from "../../../util/date";
import Qs from 'qs' 
import { getButtonList } from "../../promissionRouter";
import Toolbar from "../../components/Toolbar";
// 数据请求
import { getExamEnrollInfoListPage,getDataDictionaryList,getAreaDictionaryList,candidateSendsSMS,updateIsExemptTrain,getExamBatchPopulationList, } from '@/api/api'
export default {
    components:{Toolbar},
    data(){
        return {
            buttonList: [],
            filters:{
                searchKey:'',
                examBatch:'',
                provinceID: '',
                cityID: '',
                regionID: '',
                station: '',
                trade: '',
            },
            examBatchArr: [],
            provinceIDArr: [],
            cityIDArr: [],
            regionIDArr: [],
            stationArr: [], //岗位
            tradeArr: [], //行业
            // 批次分页
            brandListPage: {
                DataCount: 10,
                pageIndex: 1,
                pageSize: 10,
            },
            // 表格
            tableData: [],
            tableLoading: false,
            allSelect: '',
            currentRow: '',
            // 分页
            pages:{
                pageArr:[20,60,100,200,300],
                pageSize:20,
                pageIndex:1,
                dataCount:1,
            },
            // 申请组织考试
            sendMessageDialog: false,
            sendMessageLoading: false,
            sendMessageForm: {
                examTimer: '',
                examAddress: '',
            },
            sendMessageFormRules: {
                examTimer:[{required:true,message:'请选择考试时间',trigger:'blur'}],
                examAddress:[{required:true,message:'请输入考试地点',trigger:'blur'}],
            },
            DateTime: "DateTime",
            Address: "Address",
        }
    },
    methods: {
        callFunction(item) {
            console.log("item",item)
            this[item.Func].apply(this, item);
        },
        // 查询
        getPengdingBatchManagement() {
            this.getTableDataFun();
        },
        // 列表
        getTableDataFun() {
            var params = {
                keyWord: this.filters.searchKey,
                examBatchPopulationID: this.filters.examBatch || 0,
                provinceID: this.filters.provinceID,
                cityID: this.filters.cityID,
                regionID: this.filters.regionID,
                stationID: this.filters.station,
                tradeID: this.filters.trade,
                batchStatus: 0,
                isExemptTrain: 1,
                pageIndex: this.pages.pageIndex,
                pageSize: this.pages.pageSize,
            }
            this.tableLoading = true
            getExamEnrollInfoListPage(params).then(res => {
                var result = res.data
                if(result.Success) {
                    this.tableLoading = false
                    this.tableData = result.Response.Data
                    this.pages.dataCount = result.Response.DataCount
                }
            })
        },
        // 申请组织考试
        sendsSMS() {
            this.selectionData = this.$refs.multipleTable.selection
            if(this.selectionData.length == 0) {
                this.$message.warning("请选择需要申请组织考试的人群！")
            } else {
                this.sendMessageDialog = true
                this.sendMessageLoading = false
                this.sendMessageForm = {
                    examTimer: '',
                    examAddress: '',
                }
            }
        },
        sendMessageSubmit() {
            this.$refs['sendMessageFormRef'].validate(valid => {
                if(valid){
                    this.$confirm(`是否确认要对这个批次的【` + this.selectionData.length + `】位免培考试学员发送通知短信？`,"提示",{
                    }).then(() => {
                        var ids = []
                        this.selectionData.forEach(item => {
                            ids.push(item.StudentExamineeAccountID)
                        });
                        var params = {
                            ExamTime: this.formatCreateTime(this.sendMessageForm.examTimer),
                            // ExamPlace: this.sendMessageForm.examAddress,
                            StudentExamineeAccountIDs: ids,
                            ExamBatchId: this.filters.examBatch,
                        }
                        this.sendMessageLoading = true
                        candidateSendsSMS(params).then((res) => {
                            var result = res.data
                            if(result.Success) {
                                this.sendMessageDialog = false
                                this.$message.success(result.Message)
                                this.getTableDataFun();
                            } else {
                                this.$message.warning(result.Message)
                            }
                            this.sendMessageLoading = false
                        })
                    })
                    .catch((err) => {

                    })
                }
            })
        },
        // 允许考试
        updateIsExemptTrain() {
            this.selectionData = this.$refs.multipleTable.selection
            if(this.selectionData.length == 0) {
                this.$message.warning("请选择允许考试的人！")
            } else {
                this.$confirm(`已选择` + this.selectionData.length + `人，是否允许考试`,"提示",{
                }).then(() => {
                    var ids = []
                    this.selectionData.forEach(item => {
                        ids.push(item.ID)
                    });
                    var params = {
                        AllowIds: ids,
                        BatchId: this.filters.examBatch,
                    }
                    updateIsExemptTrain(params).then((res) => {
                        var result = res.data
                        if(result.Success) {
                            this.$message.success(result.Message)
                            // this.getData()
                            this.getExamBatchFun()
                        } else {
                            this.$message.error(result.Message)
                        }
                    })
                })
                .catch((err) => {

                })
            }
        },
        // 分页
        handleSizeChange(newSize){
            this.pages.pageSize = newSize   
            this.getTableDataFun()
        },
        handleCurrentChange(newPage){
            this.pages.pageIndex = newPage      
            this.getTableDataFun()
        },
        // 表格当前行改变事件
        handlerowChange(currentRow){
            this.currentRow = currentRow
        },
        // 选中表格行
        handleSelectChange(allRow){
            this.allSelect = allRow
        },
        // 获取岗位/行业
        getDataDictionaryList() {
            var params = {}
            getDataDictionaryList(params).then(res => {
                var result = res.data
                if(result.Success) {
                    result.Response.forEach(item => {
                        if(item.DataType == 0) {
                            this.tradeArr.push(item)
                        } else if(item.DataType == 1) {
                            this.stationArr.push(item)
                        }
                    });
                }
            })
        },
        // 选中省、市、区当前行
        selProvince(value){
            this.filters.cityID = ''
            this.filters.regionID = ''
            this.parentProvinceID = value
            var params = {
                level:1,
                parentId:this.parentProvinceID
            }
            this.getCity(params)
        },
        selCity(value){
            this.parentCityID = value
            var params = {
                parentId:this.parentCityID
            }
            this.getArea(params)
        },
        // 获取省、市、区的数据
        getProvince(params){
            getAreaDictionaryList(params).then(res => {
                if(res.data.Success){
                    this.provinceIDArr = res.data.Response.reverse()
                }else{return}
            }).catch(() => {})
        },
        getCity(params){
            getAreaDictionaryList(params).then(res => {
                if(res.data.Success){
                    this.cityIDArr = res.data.Response.reverse()
                }else{return}
            }).catch(() => {})
        },
        getArea(params){
            getAreaDictionaryList(params).then(res => {
                if(res.data.Success){
                    this.regionIDArr = res.data.Response.reverse()
                }else{return}
            }).catch(() => {})
        },
        // 获取批次
        getExamBatchFun() {
            var params = {
                status: 0,
                pageIndex: this.brandListPage.pageIndex,
                pageSize: this.brandListPage.pageSize,
            }
            getExamBatchPopulationList(params).then(res => {
                var result = res.data
                if(result.Success) {
                    this.examBatchArr = result.Response.Data
                    if(result.Response.Data.length > 0) {
                        this.filters.examBatch = result.Response.Data[0].ID
                    }
                    this.brandListPage.DataCount = result.Response.DataCount
                    this.getTableDataFun();
                }
            })
        },
        currentChange(val) {
            this.brandListPage.pageIndex = val
        },
        // 时间格式化
        formatCreateTime:function(row,column){
            return !row || row == ""
            ? ""
            : util.formatDate.format(new Date(row), "yyyy-MM-dd");
        }, 
    },
    watch: {
        'sendMessageForm.examTimer': function(val) {
            if(val) {
                this.DateTime = this.formatCreateTime(val)
            } else {
                this.DateTime = "DateTime"
            }
        },
        // 'sendMessageForm.examAddress': function(val) {
        //     if(val) {
        //         this.Address = val
        //     } else {
        //         this.Address = "Address"
        //     }
        // },
    },
    created(){
        this.getDataDictionaryList();
        var params = {
            level:0
        }
        this.getProvince(params)
        this.getExamBatchFun()
    },
    mounted(){
        let routers = window.localStorage.router
        ? JSON.parse(window.localStorage.router)
        : [];
        this.buttonList = getButtonList(this.$route.path, routers);
    }
}
</script>

<style lang="stylus" scoped>
    .pageBarContainer {
        display: flex;
        align-items: center;
        font-size: 14px;
    }
    .selectNumCla {
        color: #409EFF;
        font-weight: bold;
        margin: 0 5px;
        font-size: 16px;
    }
</style>