<template>
    <div>
        <el-row id="tabBarNav" :class="pagesetupHeight?'setupHei':'unSetupHei'">
            <el-col>
                <el-form :inline='true'>
                     <el-form-item label="省:">
                        <el-select
                        @change="selectRowChange1"
                        v-model="filters.selecSheng"
                        ref ='provinceRef'
                        placeholder="请选择"
                        v-loading='provinceLoading'
                        :clearable="userInfoByToken.ProvinceID == null ? true : false ">
                            <template v-for="item in shengArr">
                                <el-option
                                    :key="item.Id"
                                    :label="item.Name"
                                    :value="item.Id"
                                    :disabled='provinceIsDisabled'>
                                </el-option>
                            </template>
                        </el-select>
                    </el-form-item>
                    <el-form-item label="市:">
                        <el-select 
                        @change="selectRowChange2" 
                        v-model="filters.selecShi" 
                        placeholder="请选择" 
                        ref ='cityRef'
                        v-loading='cityLoading' 
                        :clearable="userInfoByToken.CityID == null ? true : false">
                            <template v-for="item in shiArr">
                                <el-option                            
                                    :key="item.Id"
                                    :label="item.Name"
                                    :value="item.Id"
                                    :disabled='cityIsDisabled'>
                                </el-option>
                            </template>
                        </el-select>
                    </el-form-item>
                    <el-form-item label="区/县:">
                        <el-select 
                        v-model="filters.selecXian" 
                        placeholder="请选择" 
                        ref ='regionRef'
                        v-loading='areaLoading' 
                        :clearable="userInfoByToken.RegionID == null ? true : false">
                            <template v-for="item in xianArr">
                                <el-option                            
                                    :key="item.Id"
                                    :label="item.Name"
                                    :value="item.Id"
                                    :disabled='areaIsDisabled'>
                                </el-option>
                            </template>
                        </el-select>
                    </el-form-item>
                    <el-form-item label="单位名称:">
                        <el-input v-model='filters.name' placeholder="输入单位名称" clearable @clear='clearContent'></el-input>
                    </el-form-item>
                    <el-form-item label="信用代码:">
                        <el-input v-model='filters.socialCreditCode' placeholder="请输入统一社会信用代码" clearable></el-input>
                    </el-form-item>
                    <!-- <el-form-item label="证书数量:">
                        <el-select v-model="filters.certificatesNum" placeholder="请选择证书数量">
                            <el-option
                                v-for="item in options"
                                :key="item.value"
                                :label="item.name"
                                :value="item.value"
                            >
                            </el-option>
                        </el-select>
                    </el-form-item> -->
                    <!-- <el-form-item label="已通知次数:">
                        <el-select v-model="filters.Notified" placeholder="请选择已通知次数" multiple collapse-tags clearable>
                            <el-option
                                v-for="item in NotifiedArr"
                                :key="item.value"
                                :label="item.label"
                                :value="item.value"
                            >
                            </el-option>
                        </el-select>
                    </el-form-item> -->
                    <el-form-item label="应训单位:">
                        <el-select v-model="filters.checkStatus" placeholder="请选择是否应训单位" clearable>
                            <el-option
                                v-for="item in checkStatusArr"
                                :key="item.value"
                                :label="item.label"
                                :value="item.value"
                            >
                            </el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item label="信息完整:">
                        <el-select v-model="filters.isInformaIntegrity" placeholder="请选择是否信息完整" clearable>
                            <el-option
                                v-for="item in isInformaIntegrityArr"
                                :key="item.value"
                                :label="item.label"
                                :value="item.value"
                            >
                            </el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item label="待处理单位:">
                        <el-select v-model="filters.pendingDealUnit" placeholder="请选择待处理单位" clearable>
                            <el-option
                                v-for="item in pendingDealUnitArr"
                                :key="item.value"
                                :label="item.label"
                                :value="item.value"
                            >
                            </el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item label="来源:">
                        <el-select v-model="filters.sourcesInformation" placeholder="请选择待处理单位" clearable>
                            <el-option
                                v-for="item in sourcesInformationArr"
                                :key="item.value"
                                :label="item.label"
                                :value="item.value"
                            >
                            </el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item label="是否被编辑:">
                        <el-select v-model="filters.isEdit" placeholder="请选择待处理单位" clearable>
                            <el-option
                                v-for="item in isEditArr"
                                :key="item.value"
                                :label="item.label"
                                :value="item.value"
                            >
                            </el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item>
                        <div style="display: flex;">
                            <toolbar :buttonList="buttonList" @callFunction="callFunction"></toolbar>
                            <el-button size="" @click="exportTemplate" style="margin-left: 10px;" type="warning">导出模板</el-button>
                        </div>
                    </el-form-item>
                </el-form>             
            </el-col>
        </el-row>
        <!--列表-->
        <el-table
            :data="listData"
            highlight-current-row
            v-loading="listLoading"
            border=""
            :cell-style="rowClass"
            @current-change="selectCurrentRow"
            :style="{marginTop:needHeight}"
            :maxHeight="tableHeight"
            ref="multipleTable"
        >
            <el-table-column type="selection" width="45"></el-table-column>
            <!-- <el-table-column prop="Province" align="center" label="省" width="80" show-overflow-tooltip></el-table-column> -->
            <el-table-column prop="City" align="center" label="市" width="80" show-overflow-tooltip></el-table-column>
            <el-table-column prop="Region" align="center" label="区" width="80" show-overflow-tooltip></el-table-column>
            <el-table-column prop="UnitName" align="center" label="单位名称" width="180" show-overflow-tooltip></el-table-column>
            <el-table-column prop="SocialCreditNumber" align="center" label="社会统一信用代码证" width="180" show-overflow-tooltip></el-table-column>
            <el-table-column prop="CertificateRegisterCount" align="center" label="证书数量" width="90">
                <template slot-scope='scope'>
                    <router-link :to="{path:'/SocialUnitDetail',query:{id:scope.row.ID}}" style='color:blue;'>{{scope.row.CertificateRegisterCount}}</router-link>
                </template>
            </el-table-column>
            <el-table-column prop="LiablePerson" align="center" :formatter="changeStrFun1" label="责任人" width="100"></el-table-column>
            <el-table-column prop="LiablePersonPhoneNumber" align="center" :formatter="changeStrFun2" label="责任人手机" width="130"></el-table-column>
            <el-table-column prop="Administrator" align="center" :formatter="changeStrFun3" label="安全管理人" width="130"></el-table-column>
            <el-table-column prop="AdministratorPhoneNumber" align="center" :formatter="changeStrFun4" label="安全管理人手机" width="160"></el-table-column>
            <el-table-column prop="ContactPerson" align="center" :formatter="changeStrFun5" label="法人" width="100"></el-table-column>
            <el-table-column prop="ContactPersonPhoneNumber" align="center" :formatter="changeStrFun6" label="法人手机" width="130"></el-table-column>
            <!-- <el-table-column prop="Tel" align="center" :formatter="changeStrFun7" label="固定电话" width="130"></el-table-column>
            <el-table-column prop="Address" align="center" :formatter="changeStrFun8" label="地址" width="150" show-overflow-tooltip></el-table-column>
            <el-table-column prop="NoticeTime" align="center" label="通知时间" :formatter="formatCreateTime" width="120"></el-table-column>
            <el-table-column prop="CheckStatus" align="center" label="应训单位" width="100">
                <template scope="scope">
                    <el-tag v-if='scope.row.CheckStatus' type='success'>是</el-tag>
                    <el-tag v-else type='danger'>否</el-tag>
                </template>
            </el-table-column>
            <el-table-column prop="NoticeCount" align="center" label="通知次数" width="100"></el-table-column> -->
            <el-table-column prop="OriginID" align="center" label="来源" width="100">
                <template scope="scope">
                    <el-tag v-if='scope.row.OriginID' type='success'>数据中台</el-tag>
                    <el-tag v-else type=''>单位填报</el-tag>
                </template>
            </el-table-column>
            <el-table-column prop="IsEdit" align="center" label="被编辑" width="100">
                <template scope="scope">
                    <el-tag v-if='scope.row.IsEdit' type='success'>是</el-tag>
                    <el-tag v-else type='danger'>否</el-tag>
                </template>
            </el-table-column>
            <el-table-column
                fixed="right"
                label="应训人员"
                align="center"
                width="100"
            >
                <template slot-scope="scope">
                    <el-button @click="handleClick(scope.row)" type="text" size="small">查看</el-button>
                </template>
            </el-table-column>
<!--            <el-table-column-->
<!--                fixed="right"-->
<!--                label="社消平台名单"-->
<!--                align="center"-->
<!--                width="120"-->
<!--            >-->
<!--                <template slot-scope="scope">-->
<!--                    <el-button @click="handleSocialClick(scope.row)" type="text" size="small">查看</el-button>-->
<!--                </template>-->
<!--            </el-table-column>-->
        </el-table>
        <!--底部工具条-->
        <el-col :span="24" class="pageBar">
            <el-pagination
                @size-change="handleSizeChange"
                @current-change="handleCurrentChange"
                :current-page="pages.pageIndex"
                :page-sizes="pages.pageArr"
                :page-size="pages.pageSize"
                layout="total, sizes, prev, pager, next, jumper"
                :total="pages.dataCount">
            </el-pagination>
        </el-col>
        <!-- 创建人群 -->
        <el-col :span="24" class="pageBar">
            <div class="groupClass">
                <el-form :inline='true' :model='addSocialGroupFrom' :rules='addSocialGroupRules' ref='addSocialGroupRef'>
                    <el-form-item label="查询出单位数量:">
                        {{pages.dataCount}}
                    </el-form-item>
                    <el-form-item label="">
                        <!-- 1000 -->
                    </el-form-item>
                    <el-form-item label="">
                        <!-- 1000 -->
                    </el-form-item>
                    <el-form-item label="">
                        <span style="color: #409EFF">
                            {{addSocialGroupFrom.viewName}}
                        </span>
                    </el-form-item>
                    <el-form-item label="人群名称:" prop="groupName">
                        <el-input v-model='addSocialGroupFrom.groupName' placeholder="输入人群名称" maxlength="10" clearable></el-input>
                    </el-form-item>
                    <el-form-item>
                        <el-button type="primary" @click.native="addSocialGroupSubmit" :loading="addSocialGroupLoading">创建人群</el-button>
                    </el-form-item>
                </el-form>
            </div>
        </el-col>
        <!-- 导入 -->
        <el-dialog
        title="导入"
        :visible.sync="addFormVisible"
        v-model="addFormVisible"
        :close-on-click-modal="false"
        width='500px'
        >
            <el-upload
                class="upload-demo"
                :drag="true"
                :limit="1"
                :action="importSocialUnit1"
                :headers='headers'
                :on-error="submitFileError"
                :on-success="submitFileSuccess"
                :before-upload="beforeUpload"
                :on-exceed="submitFileMore"
                :show-file-list="false"
                :multiple="false">
                <i class="el-icon-upload"></i>
                <div class="el-upload__text">将文件拖到此处，或<em>点击上传</em></div>
                <div class="el-upload__tip" slot="tip">只能上传文件，且不超过 2MB</div>
            </el-upload>
            <div slot="footer" class="dialog-footer">
                <el-button @click.native="addFormVisible = false">取消</el-button>
            </div>
        </el-dialog>
        <!-- 应训人员查看 -->
        <el-dialog
            title="查看"
            :visible.sync="unitPersonnelDialog"
            v-model="unitPersonnelDialog"
            :close-on-click-modal="false"
            width='60%'
        >
            <div class="perDialog" v-for="(item,index) in unitPersonnelData" :key="index">
                <div class="perDialogTitle">
                    <div>岗位（人群）：{{item.StationName}}</div>
                    <div class="RegionSty" v-if="item.TradeName">行业（场所）：{{item.TradeName}}</div>
                </div>
                <div class="perDialogBox">
                    <div class="ialogBoxList" v-for="(o,i) in item.SocialUnitStaffList" :key="i">
                        <div></div>
                        <div>姓名：{{o.Name}}</div>
                        <div>手机号：{{o.PhoneNumber}}</div>
                        <div></div>
                    </div>
                </div>
            </div>
            <div v-if="unitPersonnelData.length == 0" class="noPerData">暂无数据</div>
            <div slot="footer" class="dialog-footer">
                <el-button @click.native="unitPersonnelDialog = false">取消</el-button>
            </div>
        </el-dialog>
        <!-- 社消平台名单查看 -->
        <el-dialog
            title="查看"
            :visible.sync="socialPersonnelDialog"
            v-model="socialPersonnelDialog"
            :close-on-click-modal="false"
            width='60%'
        >
            <el-table
                :data="socialPersonnelData"
                highlight-current-row
                border
                v-loading="socialPersonnelLoading"
                ref="multipleTable"
            >
                <el-table-column type="index" align="center" label="序号" width="60"></el-table-column>
                <el-table-column prop="StaffName" align="center" label="姓名" width="" show-overflow-tooltip>
                    <template scope="scope">
                        <span>{{scope.row.StaffName && scope.row.StaffName != 'null' ? scope.row.StaffName : '-'}}</span>
                    </template>
                </el-table-column>
                <el-table-column prop="StaffPhoneNumber" align="center" label="手机号" width="" show-overflow-tooltip>
                    <template scope="scope">
                        <span>{{scope.row.StaffPhoneNumber && scope.row.StaffPhoneNumber != 'null' ? scope.row.StaffPhoneNumber : '-'}}</span>
                    </template>
                </el-table-column>
                <el-table-column prop="StaffIdNumber" align="center" label="身份证号" width="" show-overflow-tooltip></el-table-column>
                <el-table-column prop="OriginID" align="center" label="状态" width="">
                    <template scope="scope">
                        <el-tag v-if='scope.row.ExamTrainStatus == "UnQualified"' type='danger'>不合格</el-tag>
                        <el-tag v-else-if='scope.row.ExamTrainStatus == "OnTraining"' type=''>已在训</el-tag>
                        <el-tag v-else-if='scope.row.ExamTrainStatus == "Qualified"' type='success'>已合格</el-tag>
                        <el-tag v-else type='warning'>未知</el-tag>
                    </template>
                </el-table-column>
                <el-table-column label="操作" align="center" width="100">
                    <template slot-scope="scope">
                        <el-button @click="deleteSocialPerClick(scope.row)" type="text" size="small">删除</el-button>
                    </template>
                </el-table-column>
            </el-table>
            <el-col :span="24" class="pageBar">
                <el-pagination
                    @size-change="handleSizeChange1"
                    @current-change="handleCurrentChange1"
                    :current-page="pages1.pageIndex"
                    :page-sizes="pages1.pageArr"
                    :page-size="pages1.pageSize"
                    layout="total, sizes, prev, pager, next, jumper"
                    :total="pages1.dataCount"
                >
                </el-pagination>
            </el-col>
            <div slot="footer" class="dialog-footer">
                <el-button @click.native="socialPersonnelDialog = false">取消</el-button>
            </div>
        </el-dialog>
        <!-- 新增/编辑 -->
        <el-dialog
            :title="addOReditSocial ? '新增' : '编辑'"
            :visible.sync="addOReditDialog"
            v-model="addOReditDialog"
            :close-on-click-modal="false"
            width='60%'
        >
            <el-form :model='addSocialFrom' :rules='addSocialFromRules' ref='addSocialFromRef' label-width="230px">
                <el-form-item v-if="selectStationDialog"  label="选择单位:">
	                <el-autocomplete
			                style="width: 100%"
			                class="inline-input"
			                v-model="unit"
			                :fetch-suggestions="querySearch"
			                placeholder="请输入内容"
			                :trigger-on-focus="false"
			                @select="unitChange"
	                ></el-autocomplete>
                </el-form-item>
                <el-form-item label="单位名称:" prop="socialName">
                    <el-input :disabled="isChange" v-model='addSocialFrom.socialName' placeholder="输入单位名称" clearable></el-input>
                </el-form-item>
                <el-form-item label="社会信用代码/组织机构代码:" prop="socialCode">
                    <el-input :disabled="isChange" v-model='addSocialFrom.socialCode' onkeyup="value=value.replace(/[^\x00-\xff]/g,'')" maxlength="18" placeholder="输入社会信用代码/组织机构代码" clearable></el-input>
                </el-form-item>
                <el-form-item label="所属行业:">
                    <el-select :disabled="isChange" v-model="addSocialFrom.TradeID" placeholder="请选择所属行业" clearable style="width: 300px;">
                        <el-option
                            v-for="item in TradeIDArr"
                            :key="item.Id"
                            :label="item.Name"
                            :value="item.Id"
                        >
                        </el-option>
                    </el-select>
                </el-form-item>
                <div class="addTips">单位登记地址</div>
                <el-form-item label="省:">
	                <el-select
			                :disabled="isChange"
			                @change="selectRowChange1"
			                v-model="parentSheng"
			                placeholder="请选择"
			                ref ='cityRef'
			                v-loading='cityLoading'
			                style="width: 300px;"
			                clearable
	                >
		                <template v-for="item in shengArr">
			                <el-option
					                :key="item.Id"
					                :label="item.Name"
					                :value="item.Id"
			                >
			                </el-option>
		                </template>
	                </el-select>
                </el-form-item>
                <el-form-item label="市:">
                    <el-select
		                    :disabled="isChange"
                        @change="selectRowChange2"
                        v-model="addSocialFrom.selecShi"
                        placeholder="请选择"
                        ref ='cityRef'
                        v-loading='cityLoading'
                        style="width: 300px;"
                        clearable
                    >
                        <template v-for="item in shiArr">
                            <el-option
                                :key="item.Id"
                                :label="item.Name"
                                :value="item.Id"
                            >
                            </el-option>
                        </template>
                    </el-select>
                </el-form-item>
                <el-form-item label="区/县:">
                    <el-select
		                    :disabled="isChange"
                        @change="selectRowChange3"
                        v-model="addSocialFrom.selecXian"
                        placeholder="请选择"
                        ref ='regionRef'
                        v-loading='areaLoading'
                        style="width: 300px;"
                        clearable
                    >
                        <template v-for="item in xianArr">
                            <el-option
                                :key="item.Id"
                                :label="item.Name"
                                :value="item.Id"
                            >
                            </el-option>
                        </template>
                    </el-select>
                </el-form-item>
                <el-form-item label="乡镇/街道:">
                    <el-select
		                    :disabled="isChange"
                        v-model="addSocialFrom.selecJie"
                        placeholder="请选择"
                        ref ='regionRef'
                        v-loading='jieLoading'
                        style="width: 300px;"
                        clearable
                    >
                        <template v-for="item in jieArr">
                            <el-option
                                :key="item.ID"
                                :label="item.Name"
                                :value="item.ID"
                            >
                            </el-option>
                        </template>
                    </el-select>
                </el-form-item>
                <el-form-item label="详细地址:">
                    <el-input :disabled="isChange" v-model='addSocialFrom.addressDetail' placeholder="输入详细地址" clearable></el-input>
                </el-form-item>
                <div class="addTips">联系人</div>
                <el-form-item label="姓名:">
                    <el-input :disabled="isChange" v-model='addSocialFrom.userName' placeholder="输入姓名" clearable></el-input>
                </el-form-item>
                <el-form-item label="手机号:">
                    <el-input :disabled="isChange" v-model='addSocialFrom.userPhone' placeholder="输入手机号" clearable></el-input>
                </el-form-item>
<!--                <el-form-item label="是否应训单位:" prop="examSocial">-->
<!--                    <el-select :disabled="isChange" v-model="addSocialFrom.examSocial" placeholder="请选择是否应训单位" clearable style="width: 300px;">-->
<!--                        <el-option-->
<!--                            v-for="item in examSocialArr"-->
<!--                            :key="item.value"-->
<!--                            :label="item.label"-->
<!--                            :value="item.value"-->
<!--                        >-->
<!--                        </el-option>-->
<!--                    </el-select>-->
<!--                </el-form-item>-->
            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button @click.native="addOReditDialog = false">取消</el-button>
                <el-button v-if="!isChange" type="primary" @click.native="addOReditSubmit" :loading="addOReditLoading">确定</el-button>
                <el-button v-else type="primary" @click.native="addSubmit" :loading="addOReditLoading">确定</el-button>
            </div>
        </el-dialog>
    </div>
</template>

<script>
import Toolbar from "../../components/Toolbar"
import { getButtonList } from "../../promissionRouter";
import {
	getSocialUnitListPage,
	getUnFinishedSocialUnitListPage,
	importSocialUnit,
	getImportSocialUnitTemplateUrl,
	getAreaDictionaryList,
	getUserByToken,
	addSocialGroup,
	getSocialUnitStaffList,
	getDataDictionaryList,
	saveSocialUnit,
	deleteSocialUnitChaos,
	deletedDcSxptExamTrainDetail,
	updateSxptDataService,
	getSxptExamTrainDetail,
	getAreaDictionaryListWithCode, getSocialUnitListByKeyWord
} from '@/api/api'
import { validPhone } from "../../../util/validate";
import util from "../../../util/date";
import Qs from 'qs';
export default {
    components: {Toolbar},
    data() {
        return {
	        restaurants: [],
					unit:undefined,
	        companyArr: [],
            needHeight: 0,
            tableHeight:'500px',
            pagesetupHeight: true,
            needEditData: "",
            filters: {
                selecSheng: 1919,
                selecShi: '',
                selecXian: '',
                name: '',
                socialCreditCode: '',
                certificatesNum: '',
                Notified: [],
                checkStatus: 1,
                isInformaIntegrity: "",
                pendingDealUnit: "",
                sourcesInformation: "",
                isEdit: "",
            },
            options: [
                {value:'222',name:'请选择证书数量'},
                {value:0,name:'0'},
                {value:1,name:'1'},
                {value:2,name:'2人及以上'},
            ],
            shengArr: [],
            shiArr: [],
            xianArr: [],
            // 通知次数
            NotifiedArr:[
								{
                label:'0',value: 0
            },{
                label:'1',value: 1
            },{
                label:'2',value: 2
            },{
                label:'3',value: 3
            },{
                label:'4',value: 4
            },{
                label:'5',value: 5
            },{
                label:'大于5',value: "-1"
            }],
            checkStatusArr: [
                {label: '是',value: 1},
                {label: '否',value: 0},
            ],
            isInformaIntegrityArr: [
                {label: '是',value: 0},
                {label: '否',value: 1},
            ],
            pendingDealUnitArr: [
                {label: '全部',value: 0},
                {label: '单位名称相同',value: 1},
                {label: '信用代码相同',value: 2},
            ],
            sourcesInformationArr: [
                {label: '数据中台',value: 1},
                {label: '单位填报',value: 0},
            ],
            isEditArr: [
                {label: '是',value: 1},
                {label: '否',value: 0},
            ],
            // 父级id
            parentSheng: '',
            parentShi: '',
            // 行政区域分页参数
            pagesOpt1:{
                pageIndex:1,
                pageSize:10,
                total:0,
            },
            pagesOpt2:{
                pageIndex:1,
                pageSize:10,
                total:0,
            },
            pagesOpt3:{
                pageIndex:1,
                pageSize:10,
                total:0,
            },
            listLoading: false,
            listData: [],
            buttonList: [],
            //关于分页的obj
            pages: {
                pageSize: 20,//默认每页条数
                pageArr: [20, 40, 100, 200],//默认每页条数选择
                pageIndex: 1, //默认进入页
                dataCount: 20, //默认总页数
            },
            pages1: {
                pageSize: 20,//默认每页条数
                pageArr: [20, 40, 100, 200],//默认每页条数选择
                pageIndex: 1, //默认进入页
                dataCount: 20, //默认总页数
            },
            importSocialUnit1: '',
            addFormVisible: false,
            provinceLoading:false,
	        listLoading3 : false,
	        listLoading4 : false,
            cityLoading:false,
            areaLoading:false,
            provinceIsDisabled:false,
            cityIsDisabled:false,
            areaIsDisabled:false,
            userInfoByToken:{},
            // 创建人群
            addSocialGroupLoading: false,
            addSocialGroupFrom: {
                viewName: '',
                groupName: '',
            },
            addSocialGroupRules: {
                groupName:[{required:true,message:'请输入人群名称',trigger:'blur'}],
            },
            // 应训人员查看
            unitPersonnelDialog: false,
            unitPersonnelLoading: false,
            unitPersonnelData: [],
            // 社消平台名单查看
            socialPersonnelDialog: false,
            socialPersonnelLoading: false,
            socialPersonnelData: [],
            // 新增/编辑
            addOReditSocial: true,
            addOReditDialog: false,
            addOReditLoading: false,
            addSocialFrom: {
                socialName: "",
                socialCode: "",
                TradeID: "",
                selecShi: "",
                selecXian: "",
                selecJie: "",
                addressDetail: "",
                userName: "",
                userPhone: "",
                examSocial: "",
            },
            addSocialFromRules: {
                socialName:[{required:true,message:'输入单位名称',trigger:'blur'}],
                socialCode:[{required:true,message:'输入社会信用代码/组织机构代',trigger:'blur'}],
                TradeID:[{required:true,message:'请选择所属行业',trigger:'blur'}],
                userName:[{required:true,message:'输入姓名',trigger:'blur'}],
                userPhone:[{required:true,message:'输入手机号',trigger:'blur'}],
                // examSocial:[{required:true,message:'请选择是否应训单位',trigger:'blur'}],
            },
            TradeIDArr: [],
            jieArr: [],
            examSocialArr: [
                {label: "是",value: 1},
                {label: "否",value: 0},
            ],
            jieLoading: false,
	        isChange:false,
	        selectStationDialog:true,
	        params:{},
	        projectId:undefined,
        }
    },
    methods: {
	    querySearch(queryString, cb) {
		    console.log(queryString,'querystring')
				this.loadAll(queryString)
		    
		    // 调用 callback 返回建议列表的数据
		    setTimeout(()=>{
			    const results = this.companyArr.map(item=>({
				    value:item.UnitName,
				    address:item
			    }))
			    console.log(results,'results')
			    cb(results);
		    },2000)
	    },
	    unitChange(val){
				if (this.projectId !== 1){
					this.isChange = true
				}
		    console.log(val,'单位object数据在val.address')
		    this.addSocialFrom = {
			    socialName: val.address.UnitName,
			    socialCode: val.address.SocialCreditNumber,
			    TradeID: val.address.TradeID,
			    selecShi: val.address.CityID,
			    selecXian: val.address.RegionID,
			    selecJie: val.address.TownID,
			    addressDetail: val.address.Address,
			    userName: val.address.ContactPerson,
			    userPhone: val.address.ContactPersonPhoneNumber,
			    // examSocial: 1,
		    }
				this.parentSheng = val.address.Province
		    this.addSocialFrom.selecShi = val.address.City
		    this.addSocialFrom.selecXian = val.address.Region
		    this.addSocialFrom.selecJie = '-'
		    
		    this.getTradeFun();
		    if(val.address.CityID) {
			    this.getPlace({parentId: val.address.CityID,},2)
		    }
		    if(val.address.RegionID) {
			    this.getPlace({parentId: val.address.RegionID,},3)
		    }
				this.params = val.address
	    },
	    addSubmit(){
		    console.log(1111222222)
		    let params = {
					SocialCreditNumber: this.params.SocialCreditNumber,
		    }
		    saveSocialUnit(params).then(res => {
					var result = res.data
			    if(result.Success) {
					    this.$message({
						    message: '添加成功',
						    type: 'success'
					    })
					    this.addOReditDialog = false
					    this.getListData()
				    }else{
					    this.$message({
						    message: result.Message,
						    type: 'error'
					    })
				    }
				})
	    },
				//获取公司列表
	    async loadAll(val){
				let params = {
					keyWord:val,
				}
				const res = await getSocialUnitListByKeyWord(params)
		    if (res.data.Success){
					this.companyArr = res.data.Response
			    console.log(res.data.Response,'res单位')
		    }
      },
        // 清空input框，重新获取数据
        clearContent(){
            this.getListData();
        },
        callFunction(item) {
            this[item.Func].apply(this, item);
        },
        //   表格样式处理
        rowClass ({columnIndex}) {
            return 'padding:8px 0!important;'
        },
        getListData(e) {
            var _this = this
            var params = {
                pageIndex: this.pages.pageIndex,
                pageSize: this.pages.pageSize,
                noticeCounts: this.filters.Notified.length > 0 ? this.filters.Notified.join(',') : null,
                checkStatus: this.filters.checkStatus,
                type: this.filters.isInformaIntegrity,
                pendingType: this.filters.pendingDealUnit,
                resourceType: this.filters.sourcesInformation,
                isEdit: this.filters.isEdit,
            }
            if(this.filters.name){
                params.socialUnitName = this.filters.name
            }
            if(this.filters.socialCreditCode){
                params.socialCreditNumber = this.filters.socialCreditCode
            }
            if(this.filters.selecSheng){
                params.provinceID = this.filters.selecSheng
            }
            if(this.filters.selecShi){
                params.cityID = this.filters.selecShi
            }
            if(this.filters.selecXian){
                params.regionID = this.filters.selecXian
            }
            if(this.filters.certificatesNum != '222'){
                params.certificateRegisterCount = this.filters.certificatesNum
            }
            this.listLoading = true;
            var getTableDataFun = getSocialUnitListPage
            if(this.filters.isInformaIntegrity === 1 || this.filters.isInformaIntegrity === 0) {
                getTableDataFun = getUnFinishedSocialUnitListPage
            }
            getTableDataFun(params).then((res) => {
                var result = res.data
                if(result.Success){
                    _this.listLoading = false;
                    this.pages.dataCount = result.Response.DataCount
                    this.listData = result.Response.Data ? result.Response.Data : []
                    if(e == 1) {
                        this.$confirm(`确定将【` + this.pages.dataCount + `】单位数量创建人群？`,"提示",{
                        }).then(() => {
                            this.addSocialGroupLoading = true
                            var params = {
                                Name: this.addSocialGroupFrom.viewName,
                                CustomName: this.addSocialGroupFrom.groupName,
                                GroupType: 1,
                                NoticeCounts: this.filters.Notified.length > 0 ? this.filters.Notified : [],
                                resourceType: this.filters.sourcesInformation,
                                isEdit: this.filters.isEdit,
                                socialCreditNumber: this.filters.socialCreditCode,
                                socialUnitName: this.filters.name,
                                checkStatus: this.filters.checkStatus,
                                type: this.filters.isInformaIntegrity,
                                pendingType: this.filters.pendingDealUnit,
                            }
                            if(this.filters.certificatesNum != '222'){
                                params.certificateRegisterCount = this.filters.certificatesNum
                            }
                            // if(this.filters.selecSheng) {
                                // params.conditionJson = JSON.stringify({provinceID: this.filters.selecSheng})
                            // params.conditionJson = JSON.stringify({provinceID: 1919})
                            // if(this.filters.selecShi) {
                            //         params.conditionJson = JSON.stringify({provinceID: 1919,cityID: this.filters.selecShi})
                            //         if(this.filters.selecXian) {
                            //             params.conditionJson = JSON.stringify({provinceID: 1919, cityID: this.filters.selecShi, regionID: this.filters.selecXian})
                            //         }
                            //     // }
                            // }
                            // else {
                            //     params.conditionJson = null
                            // }
                            params.ConditionJson = {ProvinceID: 1919}
                            if(this.filters.selecShi) {
                                    params.ConditionJson = {ProvinceID: 1919,CityID: this.filters.selecShi}
                                    if(this.filters.selecXian) {
                                        params.ConditionJson = {ProvinceID: 1919, CityID: this.filters.selecShi, RegionID: this.filters.selecXian}
                                    }
                                // }
                            }
                            addSocialGroup(params).then(res => {
                                this.addSocialGroupLoading = false
                                var result = res.data
                                if(result.Success) {
                                    this.$message.success(result.Message)
                                } else {
                                    this.$message.error(result.Message)
                                }
                            })
                        })
                        .catch(() => {
                            
                        })
                    }
                }
            })
        },
        // 查询
        getEnterpriseRegisterCertificateListPage(e) {
            this.pages.pageIndex = 1
            this.getListData(e);
            // var provinceText = this.$refs.provinceRef.selected.label
            var provinceText = "广东省"
            var cityText = this.$refs.cityRef.selected.label
            var regionText = this.$refs.regionRef.selected.label
            if(provinceText && provinceText != 'undefined') {
                this.addSocialGroupFrom.viewName = this.getTimerFun() + provinceText
                if(cityText && cityText != 'undefined') {
                    this.addSocialGroupFrom.viewName = this.getTimerFun() + provinceText + cityText
                    if(regionText && regionText != 'undefined') {
                        this.addSocialGroupFrom.viewName = this.getTimerFun() + provinceText + cityText + regionText
                    }
                }
            } else {
                this.addSocialGroupFrom.viewName = ''
            }
        },
        // 导入
        importSocialUnit() {
            this.addFormVisible = true;
        },
        // 导出模板
        exportTemplate() {
            getImportSocialUnitTemplateUrl().then((res) => {
                if(res.status == 200){
                    window.open(res.data)
                }else{
                    this.$message({
                        message:'模板导出失败！',
                        type:'error'
                    })
                }
            })
        },
        // 创建人群
        addSocialGroupSubmit() {
            this.$refs['addSocialGroupRef'].validate(valid => {
                if(valid){
                    var isAddGroun = 1
                    this.getEnterpriseRegisterCertificateListPage(isAddGroun)
                }
            })
        },
        // 省选中
        selectRowChange1(row) {
            if(!row){
                this.shiArr = []
                this.xianArr = []
                this.filters.selecShi = ''
                this.filters.selecXian = ''
            }else{
                this.parentSheng = row
	            // this.pages1Change2()
                var params = {
                    parentId: row,
                    // level:1
                }
                this.getPlace(params,1)
            }           
        },
        // 市选中
        selectRowChange2(row) {
            if(!row){
                this.xianArr = []
                this.jieArr = []
                this.filters.selecXian = ''
                this.addSocialFrom.selecXian = ''
                this.addSocialFrom.selecJie = ''
            }else{
                this.xianArr = []
                this.jieArr = []
                this.filters.selecXian = ''
	            this.addSocialFrom.selecShi = row
                this.addSocialFrom.selecXian = ''
                this.addSocialFrom.selecJie = ''
                this.parentShi = row
                var params = {
                    parentId: this.parentShi,
                    // level: 2
                }
                this.getPlace(params,2)
            }          
        },
        // 区/县选中
        selectRowChange3(row) {
            if(!row){
                this.jieArr = []
                this.addSocialFrom.selecJie = ''
            }else{
                this.jieArr = []
	              this.addSocialFrom.selecXian = row
                this.addSocialFrom.selecJie = ''
                var params = {
                    parentId: row,
                    // level: 3
                }
                this.getPlace(params,3)
            }          
        },
        // 获取行政区域
        getPlace(params,level){
            switch(level){
                case 0:
                    this.provinceLoading = true
                    break;
                case 1:
                    this.cityLoading = true
                    break;
                case 2:
                    this.areaLoading = true
                    break
                case 3:
                    this.jieLoading = true
                    break
            }
            getAreaDictionaryList(params).then(res => {
                if(res.data.Success){
                    switch(level){
                        case 0:
                            this.provinceLoading = false
                            this.shengArr = res.data.Response.reverse();
                            break;
                        case 1:
                            this.cityLoading = false
                            this.shiArr = res.data.Response;
                            break;
                        case 2:
                            this.areaLoading = false
                            this.xianArr = res.data.Response;
                            break
                        case 3:
                            this.jieLoading = false
                            if(res.data.Response && res.data.Response.length > 0) {
                                this.jieArr = res.data.Response;
                            } else {
                                var newObj = {
                                    Name: '-',
                                    ID: 0,
                                }
                                this.jieArr.unshift(newObj)
                            }
                            break
                    }
                }else{return}
            }).catch(() => {})
        },
        // 行政区域选项分页
        pages1Change(value) {
            this.pagesOpt1.pageIndex = value
            var params = {
                level: 0,
            }
            this.getCityPage(params);
        },
        pages1Change2(value) {
            this.pagesOpt2.pageIndex = value
            var params = {
                parentId: this.parentSheng,
            }
            this.getCityPage2(params);
        },
        pages1Change3(value) {
            this.pagesOpt3.pageIndex = value
            var params = {
                parentId: this.parentShi,
            }
            this.getCityPage3(params);
        },
	    getCityPage2(params) {
		    console.log(params,111111)
		    this.listLoading3 = true
		    getAreaDictionaryList(params).then((res) => {
			    var result = res.data
			    if(result.Success){
				    this.shiArr = result.Response.reverse()
				    this.listLoading3 = false
			    }
		    })
	    },
	    getCityPage3(params) {
		    this.listLoading4 = true
		    getAreaDictionaryList(params).then((res) => {
			    var result = res.data
			    if(result.Success){
				    this.xianArr = result.Response.reverse()
				    this.listLoading4 = false
			    }
		    })
	    },
        selectCurrentRow(val) {
            this.needEditData = val
        },
        submitFileError() {
            this.$message({
                message:'文件上传失败！',
                type:'error'
            })
        },
        submitFileSuccess(res) {
            if(res.Success){
                this.$message({
                    message:'文件上传成功！',
                    type:'success'
                })
                this.addFormVisible = false;
                this.getListData();
            }else{
                this.$message({
                    message:res.Message,
                    type:'error'
                })
            }
        },
        // 图片上传之前函数
        beforeUpload(file) {
            const isLt2M = file.size / 1024 / 1024 > 2;
            var type = file.name.substring(file.name.lastIndexOf(".")+1);
            if (type == 'jpg' || type == 'png') {
                this.$message.error('只能上传文件!');
                return false;
            }
            if (!isLt2M === false) {
                this.$message.error('上传文件大小不能超过 2MB!');
                return false;
            }
        },
        submitFileMore() {
            this.$message({
                message:'只能上传一个文件！',
                type:'warning'
            })
        },
        // 应训人员查看
        handleClick(val) {
            this.unitPersonnelDialog = true
            var params = {
                socialUnitId: val.Id
            }
            this.unitPersonnelLoading = true
            getSocialUnitStaffList(params).then((res) => {
                var result = res.data
                if(result.Success) {
                    this.unitPersonnelData = result.Response
                }
                this.unitPersonnelLoading = false
            })
        },
        // 社消平台名单查看
        handleSocialClick(val) {
            this.needEditData = val
            this.socialPersonnelDialog = true
            this.getSocialTableDataFun()
        },
        getSocialTableDataFun() {
            var params = {
                socialCreditNumber: this.needEditData.SocialCreditNumber,
                pageIndex: this.pages1.pageIndex,
                pageSize: this.pages1.pageSize,
            }
            this.socialPersonnelLoading = true
            getSxptExamTrainDetail(params).then((res) => {
                var result = res.data
                if(result.Success) {
                    this.socialPersonnelData = result.Response.Data
                    this.pages1.dataCount = result.Response.DataCount
                }
                this.socialPersonnelLoading = false
            })
        },
        // 同步社消平台
        synchronizeDataFun() {
            if(this.needEditData){
                var params = {
                    socialUnitId: this.needEditData.ID,
                    date: this.formatCreateTime(new Date())
                }
                console.log("params",params)
                this.$confirm('确定同步社消平台吗?', '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning',
                    closeOnClickModal:false
                }).then(res => {
                    updateSxptDataService(params).then(res => {
                        var result = res.data
                        if(result.Success) {
                            this.$message.success(result.Message || "同步社消平台成功")
                        } else {
                            this.$message.warning(result.Message || "删同步社消平台失败！")
        
                        }
                    })
                }).catch(() => {})
            } else {
                this.$message({
                    message: '请选择要同步社消平台的数据',
                    type: 'warning'
                });
            }
        },
        // 删除社消平台人员
        deleteSocialPerClick(row) {
            var params = {
                id: row.Id
            }
            this.$confirm('确定删除数据吗?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning',
                closeOnClickModal:false
            }).then(res => {
                deletedDcSxptExamTrainDetail(params).then(res => {
                    var result = res.data
                    if(result.Success) {
                        this.$message.success(result.Message || "删除成功")
                        this.getSocialTableDataFun()
                    } else {
                        this.$message.warning(result.Message || "删除失败！")
    
                    }
                })
            }).catch(() => {})
        },
        // 新增/编辑
        handleAdd() {
	          if (this.projectId !==1){
		          this.selectStationDialog = true;
		          this.isChange = false
	          }
						this.unit= undefined
            this.addOReditDialog = true;
            this.addOReditLoading = false;
            this.addOReditSocial = true
            this.addSocialFrom = {
                socialName: "",
                socialCode: "",
                TradeID: "",
                selecShi: "",
                selecXian: "",
                selecJie: "",
                addressDetail: "",
                userName: "",
                userPhone: "",
                examSocial: "",
            };
						this.parentSheng = ''
            this.getTradeFun();
        },
        handleEdit() {
            if(this.needEditData){
								this.selectStationDialog = false
								this.isChange = false;
                this.addOReditDialog = true;
                this.addOReditLoading = false;
                this.addOReditSocial = false
                this.addSocialFrom = {
                    socialName: this.needEditData.UnitName,
                    socialCode: this.needEditData.SocialCreditNumber,
                    TradeID: this.needEditData.TradeID,
                    selecShi: this.needEditData.CityID,
                    selecXian: this.needEditData.RegionID,
                    selecJie: this.needEditData.TownID,
                    addressDetail: this.needEditData.Address,
                    userName: this.needEditData.ContactPerson,
                    userPhone: this.needEditData.ContactPersonPhoneNumber,
                    // examSocial: this.needEditData.CheckStatus,
                }
	            this.parentSheng = this.needEditData.Province
	            this.addSocialFrom.selecShi = this.needEditData.City
	            this.addSocialFrom.selecXian = this.needEditData.Region
                this.getTradeFun();
                if(this.needEditData.CityID) {
                    this.getPlace({parentId: this.needEditData.CityID,},2)
                }
                if(this.needEditData.RegionID) {
                    this.getPlace({parentId: this.needEditData.RegionID,},3)
                }
            }else{
                this.$message({
                    message: '请选择要编辑的数据',
                    type: 'warning'
                });
            }
        },
        // 新增/编辑提交
        addOReditSubmit() {
            this.$refs['addSocialFromRef'].validate(valid => {
                if(valid){
                    if(this.addSocialFrom.userPhone) {
                        if (!validPhone(this.addSocialFrom.userPhone)) {
                            this.$message.warning("手机号 " + this.addSocialFrom.userPhone + " 输入有误，请正确输入！")
                            return
                        }
                    }
										const sheng = this.shengArr.filter(item => item.Id == this.parentSheng)
										const shi = this.shiArr.filter(item => item.Id == this.addSocialFrom.selecShi)
										const xian = this.xianArr.filter(item => item.Id == this.addSocialFrom.selecXian)
										const jie = this.jieArr.filter(item => item.Id == this.addSocialFrom.selecJie)
	                console.log(sheng,shi,xian,jie,'0000000000')
                    var isModify = 0
                    var params = {
	                    UnitName: this.addSocialFrom.socialName,
	                    SocialCreditNumber: this.addSocialFrom.socialCode,
	                    TradeId: this.addSocialFrom.TradeID,
	                    ProvinceCode:sheng&&sheng.length>0? sheng[0].Code:undefined,
	                    CityCode:shi&&shi.length>0? shi[0].Code:undefined,
	                    DistrictCode:xian&&xian.length>0? xian[0].Code:undefined,
	                    TownCode: jie&&jie.length>0?jie[0].Code: undefined,
	                    Address: this.addSocialFrom.addressDetail,
	                    LiablePerson: this.addSocialFrom.userName,
	                    LiablePersonPhoneNumber: this.addSocialFrom.userPhone,
	                    CheckStatus: this.addSocialFrom.examSocial,
                    }
                    if(this.needEditData) {
                        if(params.CityID == this.needEditData.CityID) {
                            if(params.RegionID == this.needEditData.RegionID) {
                                if(params.TownID == this.needEditData.TownID) {
    
                                } else {
                                    isModify = 1
                                }
                            } else {
                                isModify = 1
                            }
                        } else {
                            isModify = 1
                        }
                        params.IsModify = isModify
                    }
                    this.addOReditLoading = true
                    if(this.addOReditSocial) {
                        saveSocialUnit(params).then(res => {
                            var result = res.data
                            if(result.Success) {
                                this.$message.success(result.Message)
                                this.addOReditDialog = false
                                this.getListData()
                            } else {
                                this.$message.error(result.Message)
                            }
                            this.addOReditLoading = false
                        })
                    } else {
                        params.Id = this.needEditData.Id
                        saveSocialUnit(params).then(res => {
                            var result = res.data
                            if(result.Success) {
                                this.$message.success(result.Message)
                                this.addOReditDialog = false
                                this.getListData()
                            } else {
                                this.$message.error(result.Message)
                            }
                            this.addOReditLoading = false
                        })
                    }
                }
            })
        },
        // 删除
        handleDel() {
            var selectionData = this.$refs.multipleTable.selection
            if(selectionData.length > 0) {
                this.$confirm(`确定删除选中的` + selectionData.length + `项？`,"提示",{
                }).then(() => {
                    var ids = []
                    selectionData.forEach(item => {
                        ids.push(item.Id)
                    });
                    var params = {
                        Ids: ids
                    }
                    deleteSocialUnitChaos(params).then((res) => {
                        var result = res.data
                        if(result.Success) {
                            this.$message.success(result.Message)
                            this.getListData();
                        } else {
                            this.$message.error(result.Message)
                        }
                    })
                })
                .catch((error) => {

                })
            } else {
                this.$message.warning("请至少选择一行！")
            }
        },
        // 获取行业
        getTradeFun() {
            var params = {
                dataType: 0
            }
            getDataDictionaryList(params).then((res) => {
                var result = res.data
                if(result.Success) {
                    this.TradeIDArr = result.Response
                }
            })
	        var params = {
		        parentId: 1,
		        // level: 1,
	        }
	        this.getPlace(params,0)
        },
        getToken() {
            return localStorage.getItem("Token")
        },
        // 分页
        handleCurrentChange(val) {
            // if(this.filters.selecSheng != null || this.filters.selecShi != null || this.filters.selecXian != null || this.filters.name != '' || this.filters.certificatesNum != ''){
            //     this.pages.pageIndex = 1
            // }else{
                this.pages.pageIndex = val;
            // }           
            this.getListData();
        },
        handleSizeChange(val) {
            this.pages.pageSize = val          
            this.getListData();
        },
        handleCurrentChange1(val) {
            this.pages1.pageIndex = val;
            this.getSocialTableDataFun();
        },
        handleSizeChange1(val) {
            this.pages1.pageIndex = 1;
            this.pages1.pageSize = val          
            this.getSocialTableDataFun();
        },
        //时间格式化
        formatCreateTime: function(row, column) {
            return !row || row == ""
            ? ""
            : util.formatDate.format(new Date(row), "yyyy-MM-dd");
        },
        changeStrFun1: function(val) {
            if(val.LiablePerson == "null") {
                return ""
            } else {
                return val.LiablePerson
            }
        },
        changeStrFun2: function(val) {
            if(val.LiablePersonPhoneNumber == "null") {
                return ""
            } else {
                return val.LiablePersonPhoneNumber
            }
        },
        changeStrFun3: function(val) {
            if(val.Administrator == "null") {
                return ""
            } else {
                return val.Administrator
            }
        },
        changeStrFun4: function(val) {
            if(val.AdministratorPhoneNumber == "null") {
                return ""
            } else {
                return val.AdministratorPhoneNumber
            }
        },
        changeStrFun5: function(val) {
            if(val.ContactPerson == "null") {
                return ""
            } else {
                return val.ContactPerson
            }
        },
        changeStrFun6: function(val) {
            if(val.ContactPersonPhoneNumber == "null") {
                return ""
            } else {
                return val.ContactPersonPhoneNumber
            }
        },
        changeStrFun7: function(val) {
            if(val.Tel == "null") {
                return ""
            } else {
                return val.Tel
            }
        },
        changeStrFun8: function(val) {
            if(val.Address == "null") {
                return ""
            } else {
                return val.Address
            }
        },
        // 得到用户省市区的信息
        getUserInfo(){
            // var token = localStorage.getItem("Token")
            // getUserByToken({token}).then(res => {
            //     if(res.Success){
                    var data = JSON.parse(localStorage.getItem("user")) 
                    this.userInfoByToken = data  
                    if(data.ProvinceID == null){
                        this.shengArr = []
                        this.filters.selecSheng = ''
                    }else{
                        var params = {
                            parentId:data.ProvinceID,
                            // level:1
                        }
                        this.getPlace(params,1)
                        this.provinceIsDisabled = true
                    }  
                    if(data.CityID == null){
                        this.shiArr = []
                        this.filters.selecShi = ''
                    }else{
                        var params = {
                            parentId:data.CityID,
                            // level:2
                        }
                        this.getPlace(params,2)
                        this.cityIsDisabled = true
                    }
                    if(data.RegionID == null){
                        this.xianArr = []
                        this.filters.selecXian = ''
                    }else{
                        this.areaIsDisabled = true
                    }                    
                    this.filters.selecSheng = data.ProvinceID
                    this.filters.selecShi = data.CityID
                    this.filters.selecXian = data.RegionID
                    this.getListData();
            //     }else{return}
            // }).catch(() =>{})
        },
        // 根据首页跳转修改查询条件
        updatedFilterData(data) {
            if(data.provinceID) {
                this.filters.selecSheng = data.provinceID
                var params = {
                    parentId:data.provinceID,
                    // level: 1
                }
                this.getPlace(params,1)
                this.provinceIsDisabled = true
            }
            if(data.cityID) {
                this.filters.selecShi = data.cityID
                var params = {
                    parentId:data.cityID,
                    // level: 2
                }
                this.getPlace(params,2)
                this.cityIsDisabled = true
            }
            if(data.regionID) {
                this.filters.selecXian = data.regionID
            }
            this.getListData();
        },
        getTimerFun() {
            var d = new Date();
            var curr_date = d.getDate();
            var curr_month = d.getMonth() + 1; 
            var curr_year = d.getFullYear();
            String(curr_month).length < 2 ? (curr_month = "0" + curr_month): curr_month;
            String(curr_date).length < 2 ? (curr_date = "0" + curr_date): curr_date;
            var yyyyMMdd = curr_year + "" + curr_month +""+ curr_date;
            return yyyyMMdd;
        }
    },
    computed:{
        headers() {
            return{
                "Authorization": 'Bearer ' + this.getToken() // 直接从本地获取token就行
            }
        },
    },
    created() {
				this.projectId = parseInt(window.localStorage.getItem('projectId'))
	      this.selectStationDialog = this.projectId !== 1;
        // this.importSocialUnit1 = importSocialUnit
	      const base = ''
        this.importSocialUnit1 = `${base}/sstwebapi/socialUnit/importSocialUnit` + `/` + parseInt(window.localStorage.getItem('projectId'))
    },
    mounted() {
        // var params = {
        //     level: 0,
        // }
        var params = {
            parentId: 1,
            // level: 1,
        }
        this.getPlace(params,0)
        // this.getListData();
        if(this.$route.query.userDataParams) {
            var userDataParams = JSON.parse(this.$route.query.userDataParams)
        } else {
            var userDataParams = ''
        }
        if(userDataParams) {
            this.updatedFilterData(userDataParams)
        } else {
            this.getUserInfo()
        }
        let routers = window.localStorage.router
        ? JSON.parse(window.localStorage.router)
        : [];
        this.buttonList = getButtonList(this.$route.path, routers);
    },
    updated() {
        var height = document.getElementById("tabBarNav").offsetHeight
        var tableHeight = document.getElementById('app').offsetHeight - 92 - 62 - 60 - height
        if(tableHeight > 200) {
            this.pagesetupHeight = true
            this.needHeight = height + 'px'
            this.tableHeight = tableHeight
        }else{
            this.pagesetupHeight = false
            this.needHeight = 0 + 'px'
            this.tableHeight = ''
        }
    }
}
</script>
<style>
		.pageBar{
			margin-top: 20px;
			
		}
    .el-upload-dragger{
        margin:auto;
    }
    .el-upload{
        display:block!important;
    }
    .el-upload__tip{
        margin-left:60px;
    }
    .groupClass {
        height: 45px;
    }
</style>
<style lang="stylus" scoped>
    .setupHei {
        position fixed;
        top 110px;
        z-index 100;
        background-color #fff;
    }
    .perDialog {
        border-bottom: 1px solid #999;
        margin-bottom: 25px;
    }
    .perDialogTitle {
        display: flex;
        font-weight: bolder;
    }
    .RegionSty {
        margin-left: 55px;
    }
    .perDialogBox {
        display: flex;
        flex-wrap: wrap;
    }
    .ialogBoxList {
        width: 190px;
        height: 70px;
        display: flex;
        flex-direction: column;
        justify-content: space-around;
    }
    .noPerData {
        text-align: center;
    }
    .addTips {
        margin-left: 20px;
        font-size: 16px;
        font-weight: bold;
        margin-bottom: 15px;
    }
</style>