<template>
  <div>
      <el-row>
          <el-col>
              <el-form :inline='true'>
                  <!-- <el-form-item label="证书名称:">
                      <el-select v-model='filters.sel' clearable>
                          <el-option v-for='item in options' :key='item.ID' :label='item.CertificateName' :value='item.ID'></el-option>
                      </el-select>
                  </el-form-item> -->
                  <el-form-item  label="持证人:">
                      <el-input v-model='filters.name' placeholder="请输入持证人" clearable @clear='handleClear'></el-input>
                  </el-form-item>
                  <el-form-item  label="登记单位:">
                      <el-input v-model='filters.unitName' placeholder="请输入登记单位" clearable @clear='handleClear'></el-input>
                  </el-form-item>
<!--                  <el-form-item label="证书状态:">-->
<!--                      <el-select v-model='filters.selStatus' clearable>-->
<!--                          <el-option v-for='item in options1' :key='item.ID' :label='item.CertificateName' :value='item.ID'></el-option>-->
<!--                      </el-select>-->
<!--                  </el-form-item>-->
                  <el-form-item label="岗位:">
                      <el-select v-model='filters.stationID' clearable>
                          <el-option v-for='item in stationIDArr' :key='item.Id' :label='item.Name' :value='item.Id'></el-option>
                      </el-select>
                  </el-form-item>
                  <el-form-item label="行业:">
                      <el-select v-model='filters.tradeID' clearable>
                          <el-option v-for='item in tradeIDArr' :key='item.Id' :label='item.Name' :value='item.Id'></el-option>
                      </el-select>
                  </el-form-item>
                  <el-form-item>
                      <toolbar :buttonList="buttonList" @callFunction="callFunction"></toolbar>
                  </el-form-item>
              </el-form>                           
          </el-col>
      </el-row>
      <el-table
        v-loading='listLoading'
        :data="tableData"
        border
        style="width: 100%"
        @current-change='currentChange'
        :highlight-current-row='true'
        :cell-style='rowClass'
        :header-cell-style='headerClass'>
        <el-table-column type='index' label='序号' width='60'></el-table-column>
        <el-table-column prop="CertificateHolder" label="持证人"></el-table-column>
        <el-table-column prop="MobilePhone" label="手机"></el-table-column>
        <!-- <el-table-column prop="CertificateName" label="证书名称"></el-table-column> -->
        <el-table-column prop="StationName" label="岗位名称" min-width="100"></el-table-column>
        <el-table-column prop="TradeName" label="行业名称" min-width="100"></el-table-column>
        <el-table-column prop="CertificateNumber" label="证书编号" min-width="100"></el-table-column>
        <el-table-column prop="CertificateDate" :formatter="formatCreateTime" label="发证日期" min-width="100"></el-table-column>
        <el-table-column prop="UnitName" label="当前登记单位" min-width="120">
            <template slot-scope="scope">
                <div class="UnitNameCla" @click="viewHostUnit(scope.row)">{{scope.row.UnitName}}</div>
            </template>
        </el-table-column>
        <el-table-column prop="CertificateStatus" label="证书状态" min-width="100">
            <template slot-scope="scope">
                <el-tag v-if='scope.row.CertificateStatus == 0' type=''>代发证</el-tag>
                <el-tag v-else-if='scope.row.CertificateStatus == 1' type='success'>已发证</el-tag>
                <el-tag v-else-if='scope.row.CertificateStatus == 2' type='warning'>已撤销</el-tag>
            </template>
        </el-table-column>
      </el-table>
      <!-- 分页 -->
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="pages.PageIndex"
        :page-sizes="[10, 20, 40, 100]"
        :page-size="pages.PageSize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="pages.DataCount"
        class='pageBar'>
      </el-pagination>
      <!-- 撤销证书 -->
      <el-dialog
           title="撤销证书"
           :visible.sync="cancelVisible"
           width="50%"
           :close-on-click-modal='false'
           @close='cancelFormClose'
           >
           <div>
               <div class='distance'>
                   <el-row><span>持证人：</span><span>{{currentRow.CertificateHolder}}</span></el-row>
               </div>
               <div class='distance'>
                   <el-row><span>证书名称：</span><span>{{currentRow.CertificateName}}</span></el-row>
               </div>
               <el-form :model='cancelForm' :rules='cancelFormRules' ref='cancelFormRef' label-width='80px'>
                   <el-form-item label='撤销原因' prop='reason'>
                       <el-input type='textarea' v-model='cancelForm.reason'></el-input>
                   </el-form-item>                  
               </el-form>
           </div>
           <span slot="footer" class="dialog-footer">
                <el-button @click="cancelVisible = false">取 消</el-button>
                <el-button type="primary" @click="cancelFormSubmit" :loading="addLoading">确 定</el-button>
           </span>
      </el-dialog>
        <!-- 历史登记单位 -->
        <el-dialog
            title="历史登记单位"
            :visible.sync="hostUnitDialog"
            width="60%"
            :close-on-click-modal='true'
        >
            <el-table
                v-loading='hostUnitLoading'
                :data="hostUnitList"
                border
                style="width: 100%"
                :cell-style='rowClass'
                :header-cell-style='headerClass'
            >
                <el-table-column type='index' label='序号' width='60'></el-table-column>
                <el-table-column prop="SocialUnit" label="单位名称">
                    <template scope="scope">
                        {{scope.row.SocialUnit.UnitName}}
                    </template>
                </el-table-column>
                <el-table-column prop="CertificateRegisterDate" label="登记时间"></el-table-column>
            </el-table>
            <span slot="footer" class="dialog-footer">
                <el-button @click="hostUnitDialog = false">取 消</el-button>
            </span>
        </el-dialog>
  </div>
</template>

<script>
import util from "../../../util/date";
import Toolbar from "../../components/Toolbar";
import { getButtonList } from "../../promissionRouter";
import {getCertificateInfoListPage,getIssuedCertificateInfoListPage,cancelIssuedCertificateInfo,getDataDictionaryList,getCertificateRegisterByIDList} from '@/api/api'
import Qs from 'qs'
export default {
    components:{Toolbar},
    data(){
        return {
            buttonList: [],
            filters:{
                sel:null,
                name:'',
                unitName: '',
                selStatus: '',
                stationID: '',
                tradeID: '',
            },
            currentRow:{},
            loading:false,
            addLoading:false,
            cancelVisible:false,
            options:[],
            options1:[
                {CertificateName:'所有证书',ID:'222'},
                {CertificateName:'待发证',ID:0},
                {CertificateName:'已发证',ID:1},
                {CertificateName:'已撤销',ID:2},
            ],
            stationIDArr: [], //岗位
            tradeIDArr: [], //行业
            tableData:[],
                // 分页
            pages:{
                PageIndex:1,
                PageSize:20,
                PageCount:null,
                DataCount:null
            },
                // 撤销证书数据
            cancelForm:{
                reason:''
            },
                // 撤销表单验证规则
            cancelFormRules:{
                reason:[{required:true,message:'请填写撤销原因',trigger:'blur'}]
            },
            listLoading:false,
            // 历史登记单位
            hostUnitDialog: false,
            hostUnitLoading: false,
            hostUnitList: [],
        } 
    },
    methods:{
        // 列表样式
        rowClass(){
            return 'text-align:center;'
        },
        headerClass(){
            return 'text-align:center;'
        },
        // 获取当前行
        currentChange(row){
            if(row){
                this.currentRow = row
            }else{
                this.cancelVisible = false
            }
        },
        // 筛选项input框清空
        handleClear(){
            this.getData()
        },
        // 查询
        getIssuedCertificateInfoPaperListPage(){
            this.getData()
            this.currentRow = {}
        },
        // 分页
        handleSizeChange(value){
            this.pages.PageSize = value
            this.getData()
        },
        handleCurrentChange(value){
            this.pages.PageIndex = value
            this.getData()
        },
        // 撤销证书
        handleCancelIssuedCertificateInfo(){
            var row = this.currentRow
            if(JSON.stringify(row) == '{}'){
                return this.$message({
                    showClose:true,
                    message:'选择要撤销的一行数据',
                    type:'error'
                })
            }else if(row.CertificateStatus != '1'){
                this.$message({
                    message:'已经撤销过了，不能再撤销了！',
                    type:'error'
                })
            }else{
                this.cancelVisible = true
            }
        },
        // 撤销证书表单提交
        cancelFormSubmit(){
            this.$refs['cancelFormRef'].validate(valid => {
                if(valid){
                    this.cancelVisible = false
                    this.addLoading = true
                    cancelIssuedCertificateInfo({ID:this.currentRow.ID}).then(res => {
                        if (util.isEmt.format(res)) {
                            return;
                        }
                        if(res.data.Success){
                            this.$message({
                                message:res.data.Message,
                                type:'success'
                            })
                            this.addLoading = false                            
                            this.currentRow = {}
                            this.filters.name = ''
                            this.getData()
                        }else{
                            this.$message({
                                message:res.data.Message,
                                type:'error'  
                            })
                            var _this = this
                            setTimeout(() => {
                                _this.addLoading = false
                            })
                        }
                    })
                }
            })
            
        },
        // 筛选获取证书名称
        getName(){
            getCertificateInfoListPage().then(res => {
                var data = res.data.Response.Data
                data.map(item => {
                    var obj = {}
                    obj.CertificateName = item.CertificateName
                    obj.ID = item.ID
                    this.options.push(obj)
                })
            })
        },
        getData(){
            this.listLoading = true
            var params = {
                certificateHolder:this.filters.name?this.filters.name:null,
                unitName:this.filters.unitName?this.filters.unitName:null,
                certificateDesignId:this.filters.sel,
                certificateStatus:this.filters.selStatus != '222'?this.filters.selStatus:null,
                stationId: this.filters.stationID,
                tradeId: this.filters.tradeID,
                pageIndex:this.pages.PageIndex,
                pageSize:this.pages.PageSize,
            }
            getIssuedCertificateInfoListPage(params).then(res => {
                var data = res.data.Response.Data
                if(res.data.Success){
                    this.listLoading = false
                    this.pages.DataCount = res.data.Response.DataCount
                    this.tableData = data
                }else{
                    this.listLoading = false
                    this.$message.error(res.data.Message);
                }
            })
        },
        // 获取岗位/行业
        getStationAtradeDataFun() {
            var params = {

            }
            getDataDictionaryList(params).then(res => {
                var result = res.data
                if(result.Success) {
                    this.stationIDArr = []
                    this.tradeIDArr = []
                    result.Response.forEach(item => {
                        if(item.DataType == 0) {
                            this.tradeIDArr.push(item)
                        } else if(item.DataType == 1) {
                            this.stationIDArr.push(item)
                        }
                    });
                }
            })
        },
        // 查看历史登记单位
        viewHostUnit(val) {
            this.hostUnitDialog = true
            var params = {
                certificateID: val.ID
            }
            this.hostUnitLoading = true
            getCertificateRegisterByIDList(params).then(res => {
                var result = res.data
                if(result.Success) {
                    this.hostUnitList = result.Response
                }
                this.hostUnitLoading = false
            })
        },
        // 提交表单清空
        cancelFormClose(){
            this.$refs['cancelFormRef'].resetFields()
        },
        // 时间格式化
        formatCreateTime: function(row, column) {
        return !row.CertificateDate || row.CertificateDate == ""
            ? ""
            : util.formatDate.format(new Date(row.CertificateDate), "yyyy-MM-dd");
        },
        callFunction(item) {
            this[item.Func].apply(this, item);
        }
    },
    created(){
        this.getData()
        this.getName()
        this.getStationAtradeDataFun()
        let routers = window.localStorage.router
        ? JSON.parse(window.localStorage.router)
        : [];
        this.buttonList = getButtonList(this.$route.path, routers);
    }
}
</script>

<style lang="stylus" scoped>
    .distance{
        margin-bottom:20px!important;
    }
    // .pageBar{
    //     margin-top:15px!important;
    // }
    .pageBar{
			height :60px;
    }
    .UnitNameCla {
        color: #409EFF;
        cursor: pointer;
        user-select: none;
    }
</style>